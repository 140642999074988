export function getBase64(file: Blob, _callback: (b64: string | ArrayBuffer | null) => void) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        _callback(reader.result ? reader.result.toString().split(',')[1] : null);
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export function b64toBlob(b64Data: string, contentType='', sliceSize= 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

export function formatNumber (number: string | undefined) {
    if (number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + ".00";
    }
}