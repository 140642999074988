import API from "./_axios";
import {ITest} from "../components/types/ITest";
import {ITopic} from "../components/types/ITopic";
import {IUpdateQuestionsRequest} from "../components/types/IUpdateQuestionsRequest";
import {IUserQuestionDto} from "../components/types/IUserQuestionDto";

export function fetchTestData(testId: string, _callback: (response: any) => void) {
    API.get(`/test/api/v1/test/${testId}`).then((response) => {
        _callback(response);
    })
}

export function createTest(test: ITest, _callback: (response: any) => void) {
    API.post(`/test/api/v1/test`, test).then((response) => {
        _callback(response);
    })
}

export function updateTest(test: ITest, _callback: (response: any) => void) {
    API.put(`/test/api/v1/test`, test).then((response) => {
        _callback(response);
    })
}

export function publishTest(id: string, _callback: (response: any) => void) {
    API.post(`/test/api/v1/test/${id}/publish`).then((response) => {
        _callback(response);
    })
}

export function deleteTest(id: string, _callback: (response: any) => void) {
    API.delete(`/test/api/v1/test/${id}`).then((response) => {
        _callback(response);
    })
}

export function getTestList(filter: string, _callback: (response: any) => void) {
    API.get(`/test/api/v1/test?filter=${filter}`).then((response) => {
        _callback(response);
    })
}

export function createTopic(topic: ITopic, _callback: (response: any) => void) {
    API.post(`/test/api/v1/topic`, topic).then((response) => {
        _callback(response);
    })
}

export function updateTopic(topic: ITopic, _callback: (response: any) => void) {
    API.put(`/test/api/v1/topic`, topic).then((response) => {
        _callback(response);
    })
}

export function getTopic(topicId: string, _callback: (response: any) => void) {
    API.get(`/test/api/v1/topic/${topicId}`).then((response) => {
        _callback(response);
    })
}

export function deleteTopic(topicId: string, _callback: (response: any) => void) {
    API.delete(`/test/api/v1/topic/${topicId}`).then((response) => {
        _callback(response);
    })
}

export function updateQuestions(request: IUpdateQuestionsRequest, _callback: (response: any) => void) {
    API.post(`/test/api/v1/question`, request).then((response) => {
        _callback(response);
    })
}

export function deleteQuestion(questionId: string, _callback: (response: any) => void) {
    API.delete(`/test/api/v1/question/${questionId}`).then((response) => {
        _callback(response);
    })
}

export function deleteAnswer(questionId: string, answerId: string, _callback: (response: any) => void) {
    API.delete(`/test/api/v1/question/${questionId}/answer/${answerId}`).then((response) => {
        _callback(response);
    })
}

export function getCurrentQuestion(testingId: string, _callback: (response: any) => void) {
    API.post(`/test/api/v1/testing/${testingId}/current`).then((response) => {
        _callback(response);
    })
}

export function getNextQuestion(request: IUserQuestionDto, _callback: (response: any) => void) {
    API.post(`/test/api/v1/testing/next`, request).then((response) => {
        _callback(response);
    })
}

export function submitAnswers(request: IUserQuestionDto, _callback: (response: any) => void) {
    API.post(`/test/api/v1/testing/submit`, request).then((response) => {
        _callback(response);
    })
}

export function finalize(testingId: string, _callback: (response: any) => void) {
    API.post(`/test/api/v1/testing/${testingId}/finalize`).then((response) => {
        _callback(response);
    })
}

export function getInfo(testId: string, _callback: (response: any) => void) {
    API.get(`/test/api/v1/test/${testId}/info`).then((response) => {
        _callback(response);
    })
}

export function runTest(testId: string, _callback: (response: any) => void) {
    const request = {"testId": testId}
    API.post(`/test/api/v1/testing/run`, request).then((response) => {
        _callback(response);
    })
}

export function getIncorrectQuestions(testingId: string, _callback: (response: any) => void) {
    API.get(`/test/api/v1/testing/${testingId}/result`).then((response) => {
        _callback(response);
    })
}

export function resetTesting(testingId: string, _callback: (response: any) => void) {
    API.post(`/test/api/v1/testing/${testingId}/reset`).then((response) => {
        _callback(response);
    })
}

export function deleteQuestionImage(questionId: string, _callback: (response: any) => void) {
    API.delete(`/test/api/v1/question/${questionId}/image`).then((response) => {
        _callback(response);
    })
}

export function getActivePlan(_callback: (response: any) => void) {
    API.get(`/test/api/v1/plan`).then((response) => {
        _callback(response);
    })
}

export function getPlans(_callback: (response: any) => void) {
    API.get(`/test/api/v1/plan/all`).then((response) => {
        _callback(response);
    });
}

export function addPlan(planId: string, _callback: (response: any) => void) {
    API.post(`/test/api/v1/plan/${planId}`).then((response) => {
        _callback(response);
    });
}

export function getActivePlanByUserId(userId: number, _callback: (response: any) => void) {
    API.get(`/test/api/v1/plan/user/${userId}`).then((response) => {
        _callback(response);
    })
}

export function addUserPlan(userId: number, _callback: (response: any) => void) {
    API.post(`/test/api/v1/plan/user/${userId}`).then((response) => {
        _callback(response);
    });
}