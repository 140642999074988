import React, {useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {formatDate} from "../../../../helpers/DateHelper";
import {IPaginationResponse} from "../../../../types/IPaginationResponse";
import {IAssessmentParticipantsListItem} from "../../../../types/assessment/IAssessmentParticipantsListItem";

export const AssessmentListPersonal:React.FC<{
    participants: IPaginationResponse<IAssessmentParticipantsListItem>,
    page: number,
    fetchAssessmentList: () => void,
    handleCheck: (index: number) => void,
    pickParticipant: (p: IAssessmentParticipantsListItem) => void
}> = ({participants, page, fetchAssessmentList, handleCheck, pickParticipant}) => {


    return (
        <InfiniteScroll next={fetchAssessmentList} hasMore={page < participants.totalPages} loader={<h4>Загрузка...</h4>} dataLength={participants.items.length} className="challenge-info" scrollableTarget="scrollableDiv">
            {participants.items.map((p, index) =>
                <div className="stage-card-new-work" key={index}>
                    <div className="stage-card-new-info">
                        <div className="stage-card-new-wrapper">
                            <div className="stage-card-new-wrapper">
                                <label htmlFor="add-to-rate" className="label-jury">
                                    <input type="checkbox" checked={p.allowAssessment} onChange={() => handleCheck(index)} id="add-to-rate"/>
                                    <p className="label-jury-text">Допустить к оценке</p>
                                </label>
                            </div>
                        </div>
                        <div className="graph" onClick={() => pickParticipant(p)}>
                            <p className="stage-card-new-heading">
                                {`${p.lastname} ${p.firstname}`}
                            </p>
                            <div className="stage-card-new-gap-work">
                                <p className="stage-card-new-date">
                                    {formatDate(p.solutionDate)}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="stage-card-new-wrapper-link">
                        <a href={p.solutionLink} target="_blank" className="card-standart-link">
                            Ссылка на проект
                        </a>
                    </div>
                </div>
            )}
        </InfiniteScroll>
    )
}