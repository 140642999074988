import React from "react";
import {IAssessmentParticipantsListItem} from "../../../../types/assessment/IAssessmentParticipantsListItem";
import {IAssessmentTeamListItem} from "../../../../types/assessment/IAssessmentTeamListItem";
import {CONFIG} from "../../../../../CONFIG";

export const AssessmentDetailsView:React.FC<{
    close: () => void,
    participantItem: IAssessmentParticipantsListItem | undefined,
    teamItem: IAssessmentTeamListItem | undefined}> = ({close, participantItem, teamItem}) => {

    if (teamItem) {
        return (
            <div className="right-block">
                <header className="header">
                    <p className="header-back" id="backmenu" onClick={close}>Назад</p>
                    <p className="name-menu">Члены команды</p>
                </header>
                <div className="container-right-block">
                    <div className="menu-challenge">
                        <h2 className="headline-right-block">Разработка презентации проекта</h2>
                        <ul className="responses-list">
                            <li className="respose-item">
                                <div className="username">
                                    <img src="/img/2e60650d2a1d6781a6db610d6c773.jpeg" alt="" className="header-avatar"/>
                                    <p className="fio-or-name">Проект по защите животных</p>
                                </div>

                                <div className="challenge-card">
                                    <div className="challenge-card-mini">
                                        <img src="assets/images/2e60650d2a1d6781a6db610d6c773.jpeg" alt=""
                                             className="challenge-avatar"/>
                                        <div className="user-info">
                                            <p className="challenge-header">Антон Ахламон
                                            </p>
                                            <p className="city">Капитан</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="challenge-card">
                                    <div className="challenge-card-mini">
                                        <img src="assets/images/2e60650d2a1d6781a6db610d6c773.jpeg" alt=""
                                             className="challenge-avatar"/>
                                        <div className="user-info">
                                            <p className="challenge-header">Антон Ахламон
                                            </p>
                                            <p className="city">Дизайнер</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="challenge-card">
                                    <div className="challenge-card-mini">
                                        <img src="assets/images/2e60650d2a1d6781a6db610d6c773.jpeg" alt=""
                                             className="challenge-avatar"/>
                                        <div className="user-info">
                                            <p className="challenge-header">Антон Ахламон
                                            </p>
                                            <p className="city">Местный гений</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="challenge-card">
                                    <div className="challenge-card-mini">
                                        <img src="assets/images/2e60650d2a1d6781a6db610d6c773.jpeg" alt=""
                                             className="challenge-avatar"/>
                                        <div className="user-info">
                                            <p className="challenge-header">Антон Ахламон
                                            </p>
                                            <p className="city">Журналюга</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <div className="block-input">
                                <ul className="career-list">
                                    <li className="career-item">
                                        <h4 className="career-heading">
                                            Описание проекта
                                        </h4>
                                        <p className="career-description">
                                            Задание выполнено правильно, но есть нюанс в описании
                                            проекта. Оно не вызывает желания пользоваться этим
                                            приложением, так как его функциональность ясна только
                                            разработчикам
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        )
    } else if (participantItem) {
        return (
            <div className="right-block">
                <header className="header">
                    <p className="header-back" id="backmenu" onClick={close}>Назад</p>
                    <p className="name-menu">Участник</p>
                </header>
                <div className="container-right-block">
                    <div className="menu-challenge">
                        <h2 className="headline-right-block">Профиль участника</h2>
                        <ul className="responses-list">
                            <li className="respose-item">
                                <div className="username">
                                    <img src={CONFIG.STORAGE_URL + "/" + participantItem?.userId + "/avatar/" + participantItem?.avatar} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}} alt="" className="header-avatar"/>
                                    <p className="fio-or-name">{(participantItem?.lastname || "Фамилия") + " " + (participantItem?.firstname || "Имя")}</p>
                                    <p className="city-student">{participantItem?.city}</p>
                                </div>

                                <div className="block-input">
                                    <p className="legend-about">
                                        Образование
                                    </p>
                                    <ul className="career-list">
                                        <li className="career-item">
                                            <h4 className="career-heading">
                                                {participantItem?.university}
                                            </h4>
                                        </li>
                                    </ul>
                                </div>

                                <div className="challenge-information">
                                    <p className="message-user">
                                        <a href={"https://t.me/" + participantItem?.telegram} target="_blank">Сообщение</a>
                                    </p>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    } else {
        return (<></>);
    }
}