import * as process from "process";

let BASE_URL;
let STORAGE_URL;
const PLATFORM_ADMIN_USER_TG = "https://t.me/Braim_org";
const PLATFORM_ADMIN_ORG_TG = "https://t.me/Braim_org";
const HOWTO_USER_LINK = "https://braim.org/howto-participants.html";
const HOWTO_ORG_LINK = "https://braim.org/howto-org.html";

if(process.env.TARGET_ENV === "production") {
    BASE_URL = "https://challenge.braim.org/api";
    STORAGE_URL = "https://challenge.braim.org/storage";
} else if(process.env.TARGET_ENV === "development") {
    BASE_URL = "https://dev.braim.org/api";
    STORAGE_URL = "https://dev.braim.org/storage";
} else {
    BASE_URL = "http://localhost:8080";
    STORAGE_URL = "http://localhost:9090/storage";
}


export const CONFIG = {
    BASE_URL: BASE_URL,
    STORAGE_URL: STORAGE_URL,
    PLATFORM_ADMIN_ORG_TG: PLATFORM_ADMIN_ORG_TG,
    PLATFORM_ADMIN_USER_TG: PLATFORM_ADMIN_USER_TG,
    HOWTO_ORG_LINK: HOWTO_ORG_LINK,
    HOWTO_USER_LINK: HOWTO_USER_LINK
}
