import React from "react";
import SessionModel from "../../../stores/SessionModel";
import {ProfileBusinessHeader} from "../business/ProfileBusinessHeader";
import {ProfileUniversityHeader} from "../university/ProfileUniversityHeader";
import {NavigationBusiness} from "../business/NavigationBusiness";
import {NavigationUniversity} from "../university/NavigationUniversity";

export const BlankPage: React.FC<{message: string}> = ({message}) => {

    return (
        <div className="platform-background">
            <video autoPlay muted loop playsInline className="image-back-video">
                <source src={require("../../../../public/images/main_video.mp4").default}/>
            </video>
            <div className="left-block">
                {SessionModel.isBsn() ? <ProfileBusinessHeader newPreview={null}/> : <ProfileUniversityHeader newPreview={null}/>}
                <main className="content">
                    {SessionModel.isBsn() ? <NavigationBusiness/> : <NavigationUniversity />}
                    <div className="first-message">
                        <p className="city" dangerouslySetInnerHTML={{__html: message || ""}}></p>
                    </div>
                </main>
            </div>
        </div>
    )
}