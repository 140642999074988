import React, {useEffect, useState} from "react";
import {IChallenge} from "../../../../types/IChallenge";
import API from "../../../../../requests/_axios";
import {toNumber} from "lodash";
import {CONFIG} from "../../../../../CONFIG";
import SessionModel from "../../../../../stores/SessionModel";
import {ModalMessage} from "../../../common/ModalMessage";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {ITest} from "../../../../types/ITest";
import {IChallengeCategory} from "../../../../types/IChallengeCategory";

export const ChallengeSettings: React.FC<{
  challengeId: number | undefined,
  handleSave: () => void,
  handleUploadAvatar: (id: number | undefined, src: string) => void}> = ({challengeId, handleSave, handleUploadAvatar}) => {

  const [preview, setPreview] = useState<any>();
  const [avatar, setAvatar] = React.useState<File>();
  const [error, setError] = useState("");
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [registrationEndDate, setRegistrationEndDate] = useState<any>();
  const [challenge, setChallenge] = useState<IChallenge>({
    name: "",
    description: "",
    challengeType: "INDIVIDUAL",
    partyFrom: 0,
    partyTo: 0,
    alias: ""
});
  const [startDateHint, setStartDateHint] = useState(false);
  const [endDateHint, setEndDateHint] = useState(false);
  const [regDateHint, setRegDateHint] = useState(false);
  const [passwordHint, setPasswordHint] = useState(false);
  const [tgHint, setTgHint] = useState(false);
  const [landingHint, setLandingHint] = useState(false);
  const [skillHint, setSkillHint] = useState(false);
  const [challengeCategories, setChallengeCategories] = useState<IChallengeCategory[]>()

  useEffect(() => {
    API.get("/challenge/api/v1/challenge/" + challengeId).then((response) => {
      if(response.data.startDate) {
        console.log(response.data.startDate.substring(0,16));
        setStartDate(response.data.startDate.substring(0,16));
        //response.data.startDate = response.data.startDate;
      }
      if(response.data.endDate) {
        setEndDate(response.data.endDate.substring(0,16));
        //response.data.endDate = response.data.endDate;
      }
      if(response.data.registrationEndDate) {
        setRegistrationEndDate(response.data.registrationEndDate.substring(0,16))
        //response.data.registrationEndDate = response.data.registrationEndDate;
      }
      response.data.stages = null;
      setChallenge(response.data);
      if(response.data.avatar !== null)
        setPreview(CONFIG.STORAGE_URL + "/" + response.data.userId + "/challenge/" + challengeId + "/avatar/" + response.data.avatar);
    });
    ChallengeAPI.getChallengeCategories((response) => {
      if (response.status === 200) {
        setChallengeCategories(response.data);
      }
    })
  }, []);

  const handleSubmit = () => {
    API.put("/challenge/api/v1/challenge/" + challengeId, challenge).then((response) => {
      if(response.status === 200) {
        handleSave();
      } else if (response.status === 400) {
        if (response.data.message) {
          setError(response.data.message);
        } else {
          setError("Вы не можете создать больше мероприятий, чем предусмотрено тарифным планом");
        }
      } else {
        setError("Заполните обязательные поля");
      }
    });
  }

  const handleAvatarChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList = e.target.files;
    if (!fileList) return;
    setAvatar(fileList[0]);
    const formData = new FormData();
    formData.append("file", fileList[0], fileList[0].name);
    API.post("/challenge/api/v1/challenge/" + challengeId + "/avatar", formData).then((response) => {
      if(response.status === 200) {
        setPreview(CONFIG.STORAGE_URL + "/" + challenge.userId + "/challenge/" + challengeId + "/avatar/" + response.data.avatar);
        handleUploadAvatar(challengeId, CONFIG.STORAGE_URL + "/" + challenge.userId + "/challenge/" + challengeId + "/avatar/" + response.data.avatar);
      } else {
        setError("Файл должен иметь формат .jpg и размер не должен превышать 5Мб");
      }
    });
  };

  const handleChangeStartDate = function (e: React.ChangeEvent<HTMLInputElement>) {
    setChallenge({...challenge, startDate: e.target.value + ":00:01.000+03:00"});
    setStartDate(e.target.value);
  }

  const handleChangeEndDate = function (e: React.ChangeEvent<HTMLInputElement>) {
    setChallenge({...challenge, endDate: e.target.value + ":00:59.000+03:00"});
    setEndDate(e.target.value);
  }

  const handleChangeRegistrationEndDate = function (e: React.ChangeEvent<HTMLInputElement>) {
    setChallenge({...challenge, registrationEndDate: e.target.value + ":00:59.000+03:00"});
    setRegistrationEndDate(e.target.value);
  }

  const deleteChallenge = () => {
    if (challengeId) {
      if (confirm("Вы действительно желаете удалить мероприятие?")) {
        ChallengeAPI.deleteChallenge(challengeId, (response) => {
          if (response.status === 200) {
            window.location.href = "/challenge";
          } else {
            if (response.data.message) {
              setError(response.data.message);
            }
          }
        });
      }
    }
  }

  return (
        <div className="container-right-block">
          <div className="menu-challenge">
            <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
            <h2 className="headline-right-block">Настройки</h2>
            <div className="avatar-right-block">
              <label htmlFor="addimage" className="addpost-btn">
                <img src={preview} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_challenge.svg"}} alt="" className="avater-user"/>
              </label>
                <div className="user-info">
                  <p className="fio-or-name">Аватар мероприятия*
                  </p>
                  <input type="file" name="" accept=".jpg,.jpeg" id="addimage" onChange={handleAvatarChange} style={{display: 'none' }} />
                </div>
            </div>

            <fieldset className="block-input">
              <legend className="legend">Прямая ссылка для регистрации участников</legend>
              <input type="text" value={"https://" + window.location.hostname + "/challenge/" + challengeId + "/my"} disabled={true} className="input-challenges"/>
            </fieldset>

            <fieldset className="block-input">
              <legend className="legend">Тип мероприятия*</legend>
              <select value={challenge.categoryCode} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                setChallenge({...challenge, categoryCode: e.target.value})
              }} className="input-challenges-select">
                <option value={""}>Выберите из списка</option>
                {challengeCategories && challengeCategories.map((category: IChallengeCategory, key) =>
                    <option value={category.code} key={key}>{category.name}</option>
                )}
              </select>
            </fieldset>

            <fieldset className="block-input">
              <legend className="legend">Дата и время начала*</legend>
              <input type="datetime-local" value={startDate} onChange={handleChangeStartDate} className="input-challenges" placeholder="Начните вводить" min="1700-01-01" max="2100-01-01"/>
              <div className="answer" onMouseOver={() => setStartDateHint(!startDateHint)} onMouseOut={() => setStartDateHint(!startDateHint)}></div>
              <p className="answer-text" style={{display: startDateHint ? "" : "none"}}>Время московское (UTC +3).</p>
            </fieldset>
            <fieldset className="block-input">
              <legend className="legend">Дата и время окончания*</legend>
              <input type="datetime-local" value={endDate} onChange={handleChangeEndDate} className="input-challenges" placeholder="Начните вводить" min="1700-01-01" max="2100-01-01"/>
              <div className="answer" onMouseOver={() => setEndDateHint(!endDateHint)} onMouseOut={() => setEndDateHint(!endDateHint)}></div>
              <p className="answer-text" style={{display: endDateHint ? "" : "none"}}>Время московское (UTC +3).</p>
            </fieldset>
            <fieldset className="block-input">
              <legend className="legend">Дата и время окончания регистрации*</legend>
              <input type="datetime-local" value={registrationEndDate} onChange={handleChangeRegistrationEndDate} className="input-challenges" placeholder="Начните вводить" min="1700-01-01" max="2100-01-01"/>
              <div className="answer" onMouseOver={() => setRegDateHint(!regDateHint)} onMouseOut={() => setRegDateHint(!regDateHint)}></div>
              <p className="answer-text" style={{display: regDateHint ? "" : "none"}}>Время московское (UTC +3).</p>
            </fieldset>
            <fieldset className="block-input">
              <legend className="legend">Название мероприятия*</legend>
              <input type="text" value={challenge.name} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                setChallenge({...challenge, name: e.target.value});
              }} className="input-challenges" placeholder="Введите текст"/>
            </fieldset>
            <fieldset className="block-input">
              <legend className="legend">Логин Telegram администратора мероприятия*</legend>
              <input type="text" value={challenge.adminTelegram} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                setChallenge({...challenge, adminTelegram: e.target.value});
              }} className="input-challenges" placeholder="Shalashsi"/>
              <div className="answer" onMouseOver={() => setTgHint(!tgHint)} onMouseOut={() => setTgHint(!tgHint)}></div>
              <p className="answer-text" style={{display: tgHint ? "" : "none"}}>По этому аккаунту к вам могут обращаться участники мероприятий за разъяснениями. Можно указать ссылку на чат в Telegram</p>
            </fieldset>
            {SessionModel.isAdmin() ?
                <fieldset className="block-input">
                  <legend className="legend">Название организации*</legend>
                  <input type="text" value={challenge.orgName} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                    setChallenge({...challenge, orgName: e.target.value});
                  }} className="input-challenges" placeholder="Введите текст"/>
                </fieldset>
                :
                <></>
            }
            <fieldset className="block-input">
              <legend className="legend">Сайт</legend>
              <input type="text" value={challenge.site || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                setChallenge({...challenge, site: e.target.value});
              }} className="input-challenges" placeholder="Вставьте ссылку на лендинг"/>
              <div className="answer" onMouseOver={() => setLandingHint(!landingHint)} onMouseOut={() => setLandingHint(!landingHint)}></div>
              <p className="answer-text" style={{display: landingHint ? "" : "none"}}>Укажите ссылку на имеющуюся страницу мероприятия, либо пропустите этот шаг, чтобы создать лендинг мероприятия позже</p>
            </fieldset>
            {SessionModel.isAdmin() ?
                <fieldset className="block-input">
                  <legend className="legend">Короткое имя для лендинга</legend>
                  <input type="text" value={challenge.alias} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                    setChallenge({...challenge, alias: e.target.value});
                  }} className="input-challenges" placeholder="Введите текст"/>
                </fieldset>
                :
                <></>
            }
            <fieldset className="block-input">
              <legend className="legend">Описание*</legend>
              <textarea className="input-challenges" value={challenge.description} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>)=> {
                setChallenge({...challenge, description: e.target.value});
              }} cols={30} rows={10} maxLength={200} placeholder="Введите текст (до 200 символов)"/>
            </fieldset>
            <fieldset className="block-input">
              <legend className="legend">Награды*</legend>
              <input type="text" value={challenge.fund || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                setChallenge({...challenge, fund: e.target.value});
              }} className="input-challenges" placeholder="300 000 + карьерные возможности..."/>
            </fieldset>
            <fieldset className="block-input-flex">
              <legend className="legend">Формат*</legend>
              <label className="input-challenges-flex">
                <input type="radio" value="COMMAND" name="challengeType" onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                  setChallenge({...challenge, challengeType: e.target.value});}} checked={"COMMAND" === challenge.challengeType} className="radio-btn"/>
                  <span className="span-radio">Командный</span>
              </label>
              <label className="input-challenges-flex">
                <input type="radio" value="INDIVIDUAL" name="challengeType" onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                  setChallenge({...challenge, challengeType: e.target.value});}} checked={"INDIVIDUAL" === challenge.challengeType} className="radio-btn"/>
                  <span className="span-radio">Индивидуальный</span>
              </label>
            </fieldset>
            <fieldset className="block-input" style={challenge.challengeType === "COMMAND" ? {} : {display: 'none'}}>
              <legend className="legend">Минимальное количество участников в команде*</legend>
              <input type="number" value={challenge.partyFrom} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                setChallenge({...challenge, partyFrom: toNumber(e.target.value)});
              }} className="input-challenges" placeholder="введите количество"/>
            </fieldset>
            <fieldset className="block-input" style={challenge.challengeType === "COMMAND" ? {} : {display: 'none'}}>
              <legend className="legend">Маскимальное количество участников в команде*</legend>
              <input type="number" value={challenge.partyTo} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                setChallenge({...challenge, partyTo: toNumber(e.target.value)});
              }} className="input-challenges" placeholder="введите количество"/>
            </fieldset>
            <fieldset className="block-input">
              <legend className="legend">Ссылка на правила*</legend>
              <input type="text" value={challenge.rules || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                setChallenge({...challenge, rules: e.target.value});
              }} className="input-challenges" placeholder="Вставьте ссылку"/>
              <div className="btn-info">
                <h4 className="btn-info-heading">
                  Важно
                </h4>
                <p className="btn-info-text">
                  Укажите ссылку на документ с правилами участия в мероприятии. При необходимости вы можете воспользоваться <a href="https://docs.google.com/document/d/197Ul8FJ0WNPGLclZT8ICcwi2RFqtMiwPHiZksn3oIGA/edit?usp=sharing" className="active-link" target="_blank">шаблоном</a>
                </p>
              </div>
            </fieldset>
            <fieldset className="block-input">
              <legend className="legend">Навыки</legend>
              <input type="text" value={challenge.skills} onChange={(e => setChallenge({...challenge, skills: e.target.value}))} placeholder="программирование, python, AI" className="input-challenges"/>
              <div className="answer" onMouseOver={() => setSkillHint(!skillHint)} onMouseOut={() => setSkillHint(!skillHint)}></div>
              <p className="answer-text" style={{display: skillHint ? "" : "none"}}>Укажите через запятую навыки, которые могут понадобиться для успешного участия в мероприятии</p>
            </fieldset>
            <fieldset className="block-input">
              <legend className="legend">Пароль</legend>
              <input type="text" value={challenge.password || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                setChallenge({...challenge, password: e.target.value});
              }} className="input-challenges" placeholder="Оставьте пустым, если не требуется"/>
              <div className="answer" onMouseOver={() => setPasswordHint(!passwordHint)} onMouseOut={() => setPasswordHint(!passwordHint)}></div>
              <p className="answer-text" style={{display: passwordHint ? "" : "none"}}>
                При регистрации участник должен будет ввести пароль. Оставьте пустым, если не требуется
              </p>
            </fieldset>
            <button className="btn-main" onClick={() => handleSubmit()}>Сохранить</button>
            <button className="btn-no" onClick={() => deleteChallenge()}>Удалить мероприятие</button>
          </div>
        </div>
  )
}