import API from "./_axios";
import {IChallengeCertificateDto} from "../components/types/IChallengeCertificateDto";

export function getChallengeCertificates(filter: string, _callback: (response: any) => void) {
    API.get(`/certificate/api/v1/challenge?filter=${filter}`).then((response) => {
       _callback(response);
    });
}

export function getChallengeCertificate(certificateId: string, isDto: boolean, _callback: (response: any) => void) {
    API.get(`/certificate/api/v1/challenge/${certificateId}?isDto=${isDto}`).then((response) => {
        _callback(response);
    });
}

export function getChallengeCertificatePreview(certificateId: string, _callback: (response: any) => void) {
    API.get(`/certificate/api/v1/challenge/${certificateId}/preview`).then((response) => {
        _callback(response);
    });
}

export function deleteChallengeCertificate(certificateId: string, _callback: (response: any) => void) {
    API.delete(`/certificate/api/v1/challenge/${certificateId}`).then((response) => {
        _callback(response);
    });
}

export function createCertificate(certificate: IChallengeCertificateDto, _callback: (response: any) => void) {
    API.post(`/certificate/api/v1/challenge`, certificate).then((response) => {
        _callback(response);
    })
}

export function update(certificate: IChallengeCertificateDto, _callback: (response: any) => void) {
    API.put(`/certificate/api/v1/challenge`, certificate).then((response) => {
        _callback(response);
    })
}

export function publish(certificateId: string, _callback: (response: any) => void) {
    API.post(`/certificate/api/v1/challenge/${certificateId}/publish`).then((response) => {
        _callback(response);
    })
}

export function getChallengeCertificateView(certificateId: string, _callback: (response: any) => void) {
    API.get(`/certificate/api/v1/challenge/${certificateId}/view`).then((response) => {
        _callback(response);
    })
}

export function getChallengeCertificateViews(body: string [], _callback: (response: any) => void) {
    API.post(`/certificate/api/v1/challenge/views`, body).then((response) => {
        _callback(response);
    })
}

export function getActivePlan(_callback: (response: any) => void) {
    API.get(`/certificate/api/v1/plan`).then((response) => {
        _callback(response);
    });
}

export function getPlans(_callback: (response: any) => void) {
    API.get(`/certificate/api/v1/plan/all`).then((response) => {
        _callback(response);
    });
}

export function addPlan(planId: string, _callback: (response: any) => void) {
    API.post(`/certificate/api/v1/plan/${planId}`).then((response) => {
        _callback(response);
    });
}

export function getByUserId(userId: number, _callback: (response: any) => void) {
    API.get(`/certificate/api/v1/plan/user/${userId}`).then((response) => {
        _callback(response);
    });
}

export function addUserPlan(userId: number, _callback: (response: any) => void) {
    API.post(`/certificate/api/v1/plan/user/${userId}`).then((response) => {
        _callback(response);
    });
}