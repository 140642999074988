import React, {useEffect, useState} from "react";
import API from "../requests/_axios";
import {useNavigate, useSearchParams} from 'react-router-dom';
import SessionModel from "../stores/SessionModel";
import cookie from "js-cookie";
import {MainPageHelp} from "./profile/common/MainPageHelp";

export const SignIn = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    API.post("/sso/api/v1/user").then((response) => {
      if (response.status === 200)
        navigate("/profile");
      else {
        localStorage.removeItem("token");
        cookie.remove("token");
      }
    })
  }, []);

  const handleUsername = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUsername(event.target.value);
  }

  const handlePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleSignIn()
      }

    return <input type="text" onKeyDown={handleKeyDown} />
  }

  const handleSignIn = () => {
    setError("");
    let personalPath = "/challenge/api/v1/personal/profile/last-seen";
    let universityPath = "/challenge/api/v1/university/profile/last-seen";
    let bsnPath = "/challenge/api/v1/business/profile/last-seen";
    let currentPath = "";
    API.post<any>('/sso/api/v1/signin', {username: username, password: password}).then((response) => {
      if(response.status === 200) {
        localStorage.removeItem("token");
        localStorage.setItem("token", response.data.token);
        if (SessionModel.getCurrentUserAuthData().type === "PERSONAL") {
          currentPath = personalPath;
        } else if (SessionModel.getCurrentUserAuthData().type === "EDU") {
          currentPath = universityPath;
        } else if (SessionModel.getCurrentUserAuthData().type === "BSN") {
          currentPath = bsnPath;
        }
        if(SessionModel.getCurrentUserAuthData().type !== "ADMIN" && SessionModel.getCurrentUserAuthData().type !== "MODERATOR") {
          setTimeout(() => {
            API.post(currentPath, {}, {headers: {"Authorization": "Bearer " + response.data.token}}).then((response) => {
              if(response.status === 200) {
                const redirectTo = cookie.get("afterLoginRedirect") || "/profile";
                cookie.remove("afterLoginRedirect");
                navigate(redirectTo);
              } else {
                console.log("Something fails");
              }
            });
          }, 100);

        } else {
          setTimeout(() => { navigate("/profile"); }, 100 );
        }
      } else if (response.status === 401) {
        alert("Неправильный логин или пароль");
      } else if (response.status === 403) {
        alert(response.data.message);
      } else {
        alert("Произошла ошибка");
      }
    })
  }


  return (
      <div className="reg-background">
        <MainPageHelp/>
        <a href="https://braim.org" target="_blank">
          <img src={require("../../public/images/logo_white.svg").default} alt="" className="logo-reg"/>
        </a>
        <video autoPlay muted loop playsInline className="video-reg">
          <source src={require("../../public/images/background.mp4").default}/>
        </video>
        <div className="container-registration">
          <div className="registration">
            <h3 className="heading-registration">
              Авторизация
            </h3>
            <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : ""}}>Ошибка! {error}</p>
            {
              searchParams.get("message") !== "" ? (
                  searchParams.get("message") == "link_reset" ? ("Нава вашу почту отправлена новая ссылка для подтверждения регистрации") : ("")
              ) : searchParams.get("message") == "password_reset" ? ("На вашу почту отправлен новый пароль") : ("")
            }
            <input type="email" value={username} onChange={handleUsername} onKeyDown={handleKeyDown} className="input-standart" name="email" placeholder="E-mail"/>
            <input type="password" value={password} onChange={handlePassword} onKeyDown={handleKeyDown} className="input-standart" name="password" placeholder="Пароль"/>
            <button onClick={handleSignIn} className="btn-main">Войти</button>
            <a href="/password/reset" className="code-error">Я забыл пароль</a>
            <a href="/edu/signup" className="code-error">Зарегистрировать образовательную организацию</a>
            <a href="/bsn/signup" className="code-error">Зарегистрировать компанию/НКО</a>
            <a href="/personal/signup" className="code-error">Зарегистрироваться как участник</a>
          </div>
        </div>
      </div>
  )
}