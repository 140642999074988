import {useSearchParams} from "react-router-dom";
import {WalletTab} from "./WalletTab";
import {PlanTab} from "./PlanTab";
import {EnrollTab} from "./EnrollTab";
import {PaymentTab} from "./PaymentTab";
import SessionModel from "../../../../stores/SessionModel";
import {AdminPlan} from "../../admin/plan/AdminPlan";
import * as ChallengeAPI from "../../../../requests/ChallengeAPI";
import React, {useEffect, useState} from "react";
import {BlankPage} from "../BlankPage";
import {Spinner} from "../Spinner";

interface IProfileData {
    isApproved: boolean;
}

export const Plan = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [profileData, setProfileData] = useState<IProfileData>();

    useEffect(() => {
        getProfile();
    },[]);

    const getProfile = () => {
        ChallengeAPI.getProfile((response) => {
            if (response.status === 200) {
                setProfileData(response.data);
            }
        })
    }

    if (SessionModel.isAdmin()) {
        return <AdminPlan/>
    } else {
        if (profileData) {
            if (profileData.isApproved) {
                if (searchParams.get("tab") === "wallet") {
                    return <WalletTab/>
                } else if (searchParams.get("tab") === "plan") {
                    return <PlanTab/>
                } else if (searchParams.get("tab") === "payments") {
                    return <PaymentTab/>
                } else if (searchParams.get("tab") === "enroll") {
                    return <EnrollTab/>
                } else {
                    return <WalletTab/>
                }
            } else {
                return (
                    <BlankPage message={"Функционал раздела «Оплата» будет доступен после того, как вашу организацию одобрит модератор. Для этого в разделе «Профиль»:<br/> 1. Заполните все поля анкеты; " +
                        "<br/>2. Скачайте бланк письма, заполните его, подпишите у руководителя и пригрозите в личный кабинет; " +
                        "<br/>3. Нажмите на кнопку «Отправить на модерацию»."}/>
                )
            }
        } else {
            return <Spinner text={"Подождите..."} enabled={true}/>
        }
    }
}