import React, {useState} from "react";
import {IChallenge} from "../../../../types/IChallenge";
import {IChallengeStage} from "../../../../types/IChallengeStage";
import API from "../../../../../requests/_axios";
import {useNavigate, useParams} from "react-router-dom";
import {StageButtonRedisign} from "./StageButtonRedisign";

export const StageList: React.FC<{
    challenge: IChallenge,
    showStage: (id: number | undefined) => void,
    enabled: boolean,
    showRating: (id: number | undefined) => void,
    showMyResults: (id: number | undefined) => void}> = ({ challenge, showStage, enabled, showRating, showMyResults }) => {

    const navigate = useNavigate();
    const params = useParams();
    const [error, setError] = useState("");

    const leaveChallenge = () => {
        API.delete(`/challenge/api/v1/challenge/${params.id}/my`).then((response) => {
            if(response.status === 200) {
                navigate("/challenge");
            } else {
                setError("Вы не можете отказаться от участия, поскольку загрузили решение или выполнили тест по одному из заданий");
            }
        })
    }

  if(enabled) {
    return (
        <div className="challenge-info">
            {challenge.stages?.length === 0 && challenge.challengeType === "COMMAND" ? (
                <div className="first-message">
                    <p className="fio-or-name">
                        Создайте или выберите команду
                    </p>
                    <p className="city">
                        Для просмотра этапов и заданий конкурса вы должны состоять в команде. Капитану необходимо нажать на кнопку "Закончить формирование команды". <a href="https://youtu.be/D5h9lhOizfk" target="_blank" className="active-link">Видеоинструкции</a>
                    </p>
                </div>
            ) : (<>
                <div className="first-message">
                    <p className="city">
                        Нажмите на активный этап, чтобы ознакомиться с заданием. <a href="https://youtu.be/D5h9lhOizfk" target="_blank" className="active-link">Видеоинструкция</a>  по работе с разделом
                    </p>
                </div>
            </>)}
            <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : ""}}>Ошибка! {error}</p>
            {challenge.stages && challenge.stages.map((s: IChallengeStage, index) =>
              <StageButtonRedisign stage={s} showStage={showStage} key={s.id} index={index+1} showRating={showRating} showMyResults={showMyResults}/>
          )}
            {challenge.challengeType !== "COMMAND" ?
                <button className="btn-no" onClick={() => {if(window.confirm('Вы действительно желаете покинуть мероприятие? Если данный конкурс командный, а вы капитан, то ваша команда будет также удалена')){leaveChallenge()}}}>Отказаться от участия</button> : <></>}
        </div>
    )
  } else {
      return (<></>)
  }
}