import {ProfileUniversityHeader} from "../../university/ProfileUniversityHeader";
import {NavigationUniversity} from "../../university/NavigationUniversity";
import {PlanHeader} from "./PlanHeader";
import React, {useEffect, useState} from "react";
import * as WalletAPI from "../../../../requests/WalletAPI";
import {ModalMessage} from "../ModalMessage";
import {IOperationLog} from "../../../types/IOperationLog";
import {formatDate, getStringMonth} from "../../../helpers/DateHelper";
import SessionModel from "../../../../stores/SessionModel";
import {ProfileBusinessHeader} from "../../business/ProfileBusinessHeader";
import {NavigationBusiness} from "../../business/NavigationBusiness";

export const EnrollTab = () => {

    const [error, setError] = useState("");
    const [operations, setOperations] = useState<any>([]);

    useEffect(() => {
        WalletAPI.getOperationList("FILL", (response) => {
           if (response.status === 200) {
               setOperations(response.data);
           } else {
               setError("Ошибка при получении операций");
           }
        });
    }, []);

    const getMonthYear = (date: string) => {
        return getStringMonth(date) + ", " + new Date(date).getFullYear();
    }

    return (
        <div className="platform-background">
            <video autoPlay muted loop playsInline className="image-back-video">
                <source src={require("../../../../../public/images/main_video.mp4").default}/>
            </video>
            <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
            <div className="left-block">
                {SessionModel.isBsn() ? <ProfileBusinessHeader newPreview={null}/> : <ProfileUniversityHeader newPreview={null}/>}
                <main className="content">
                    {SessionModel.isBsn() ? <NavigationBusiness/> : <NavigationUniversity />}
                    <div className="main-list-center">
                        <PlanHeader/>
                        <div className="challenge-info">
                            {!SessionModel.isAdmin() ?
                                <div className="first-message">
                                    <p className="city">
                                        <a href="https://disk.yandex.ru/d/h5h1ikVGh7PAsg" target="_blank" className="active-link">Видеоинструкции</a> по работе с сервисом "Оплата"
                                    </p>
                                </div>
                                :
                                <></>
                            }
                            <ul className="date-month-list">
                                {operations?.map((operation: IOperationLog, key: number) =>
                                    <React.Fragment key={key}>
                                     {key === 0 || getMonthYear(operation.createdAt) !== getMonthYear(operations[key - 1].createdAt) ?
                                         <li className="day-moth-item">
                                             <h2 className="day-write-month">
                                                 {getMonthYear(operation.createdAt)}
                                             </h2>
                                         </li>
                                         :
                                         <></>
                                     }
                                     {key === 0 || formatDate(operation.createdAt) !== formatDate(operations[key - 1].createdAt) ?
                                         <li className="day-month-item">
                                             <p className="day-write-off-on">
                                                 {formatDate(operation.createdAt)}
                                             </p>
                                         </li>
                                         :
                                         <></>
                                     }
                                     <li className="day-month-item">
                                         <p className={operation.status === "FAIL" ? "day-failed-summ" : operation.status === "NEW" ? "wait-status" : "day-write-on-summ"}>
                                             {operation.amount / 100}
                                         </p>
                                         <p className="day-write-off-desc">
                                             {operation.description} {
                                                 operation.paymentSource === "BILL" ?
                                                     operation.status === "NEW" ? <a href={"/payment/bill/" + operation.transactionId} className="active-link">ссылка на счет</a>: " (счет)" : " (карта)"
                                         }
                                         </p>
                                     </li>
                                    </React.Fragment>
                                )}
                            </ul>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    )
}