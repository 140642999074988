import API from "./_axios";

export function loginAs(username: string) {
    API.post<any>('/sso/api/v1/signin/as', {username: username}).then((response) => {
        if(response.status === 200) {
            localStorage.removeItem("token");
            localStorage.setItem("token", response.data.token);
            setTimeout(() => { window.location.href="/profile"; }, 100 );
        } else if (response.status === 401) {
            alert("Неправильный логин или пароль");
        } else if (response.status === 403) {
            alert(response.data.message);
        } else {
            alert("Произошла ошибка");
        }
    })
}