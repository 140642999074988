import React from "react";
import SessionModel from "../../../stores/SessionModel";
import {ProfileUniversity} from "../university/ProfileUniversity";
import {ProfileIndividual} from "../personal/profile/ProfileIndividual";
import {UniversityList} from "../admin/UniversityList";
import {ProfileBusiness} from "../business/ProfileBusiness";

export const Profile = () => {
    let panel;

    if(SessionModel.getCurrentUserAuthData().type === "EDU") {
      panel = <ProfileUniversity userId={null}/>
    } else if (SessionModel.getCurrentUserAuthData().type === "PERSONAL") {
      panel = <ProfileIndividual userId={null}/>
    } else if (SessionModel.getCurrentUserAuthData().type === "ADMIN" || SessionModel.isModerator()) {
      panel = <UniversityList/>
    } else if (SessionModel.getCurrentUserAuthData().type === "BSN") {
      panel = <ProfileBusiness userId={null}/>
    } else {
        SessionModel.removeToken();
        window.location.href = '/';
    }
    return (
        <div>
          {panel}
        </div>
    )

}