import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {IAssessmentSettings} from "../../../../types/assessment/IAssessmentSettings";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {toNumber} from "lodash";
import {ModalMessage} from "../../../common/ModalMessage";

export const AssessmentSettings:React.FC<{close: () => void}> = ({close}) => {

    const params = useParams();
    const [settings, setSettings] = useState<IAssessmentSettings>();
    const [error, setError] = useState("");

    useEffect(() => {
        ChallengeAPI.getAssessmentSettings(toNumber(params.id), toNumber(params.stageId), (response) => {
            if (response.status === 200) {
                setSettings(response.data);
            } else if (response.status === 404) {
                const newSettings: IAssessmentSettings = {
                    challengeId: toNumber(params.id),
                    stageId: toNumber(params.stageId),
                    showPersonal: false,
                    allowComments: false
                }
                setSettings(newSettings);
            } else {
                setError("Ошибка при получении настроек");
            }
        });
    }, []);

    const save = () => {
        if (settings) {
            ChallengeAPI.saveAssessmentSettings(toNumber(params.id), toNumber(params.stageId), settings, (response) => {
                if (response.status === 200) {
                    window.location.reload();
                } else {
                    setError("Ошибка сохранения настроек");
                }
            });
        }
    }

    return (
        <div className="right-block">
            <header className="header">
                <p className="header-back" id="backmenu" onClick={close}>Назад</p>
                <p className="name-menu">Настройки</p>
            </header>
            <div className="container-right-block">
                <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                <div className="menu-challenge">
                    <h2 className="headline-right-block">Настройки доступа</h2>
                    <fieldset className="block-input-flex-column">
                        <legend className="legend">Отображение данных участников</legend>
                        <label htmlFor="showPersonal" className="input-challenges-flex">
                            <input type="radio" name="status" id="showPersonal" className="radio-btn" checked={settings?.showPersonal}
                                   onClick={() => setSettings({...settings, showPersonal: true})}/>
                                <span className="span-radio">Отображать</span>
                        </label>
                        <label htmlFor="notShowPersonal" className="input-challenges-flex">
                            <input type="radio" name="status" id="notShowPersonal" className="radio-btn" checked={!settings?.showPersonal}
                                   onClick={() => setSettings({...settings, showPersonal: false})}/>
                                <span className="span-radio">Не отображать</span>
                        </label>
                    </fieldset>
                    <p className="btn-info-text">
                        Вы можете настраивать отображение данных участников и не только
                    </p>
                    <fieldset className="block-input-flex-column">
                        <legend className="legend">Разрешить комментарии экспертов</legend>
                        <label htmlFor="allowComments" className="input-challenges-flex">
                            <input type="radio" name="status1" id="allowComments" className="radio-btn" checked={settings?.allowComments}
                                   onClick={() => setSettings({...settings, allowComments: true})}/>
                                <span className="span-radio">Разрешить</span>
                        </label>
                        <label htmlFor="notAllowComments" className="input-challenges-flex">
                            <input type="radio" name="status1" id="notAllowComments" className="radio-btn" checked={!settings?.allowComments}
                                   onClick={() => setSettings({...settings, allowComments: false})}/>
                                <span className="span-radio">Запретить</span>
                        </label>
                    </fieldset>
                    <input type="button" className="btn-main" value="Сохранить" onClick={save}/>
                    {/*<fieldset className="block-input-flex-column">
                        <legend className="legend">Разрешить повторную оценку</legend>
                        <p className="btn-info-text">
                            Вы можете настраивать отображение данных участников и не только. Всё
                            что сюда заходят добавить админы
                        </p>
                        <label className="input-challenges-flex">
                            <input type="radio" name="status" id="" className="radio-btn"/>
                                <span className="span-radio">Отображать</span>
                        </label>
                        <label className="input-challenges-flex">
                            <input type="radio" name="status" id="" className="radio-btn"/>
                                <span className="span-radio">Не отображать</span>
                        </label>
                    </fieldset>*/}
                </div>
            </div>
        </div>
    )
}