import React from "react";
import {useSearchParams} from "react-router-dom";

export const PlanHeader = () => {

    const[searchParams, setSearchParams] = useSearchParams();

    return (
        <div className="my-challenge">
            <div className="user-info">
                <p className="fio-or-name">Оплата</p>
            </div>
            <ul className="nav-block-center">
                <li className="nav-center-item">
                    <a href="?tab=wallet" className={searchParams.get("tab") === "wallet" || !searchParams.get("tab") ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Баланс</a>
                </li>
                <li className="nav-center-item">
                    <a href="?tab=plan" className={searchParams.get("tab") === "plan" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Тарифы</a>
                </li>
                <li className="nav-center-item">
                    <a href="?tab=payments"
                       className={searchParams.get("tab") === "payments" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Списания</a>
                </li>
                <li className="nav-center-item">
                    <a href="?tab=enroll"
                       className={searchParams.get("tab") === "enroll" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Пополнения</a>
                </li>
            </ul>
        </div>
    )
}