import React, {useEffect, useState} from "react";
import API from "../../../../../requests/_axios";
import {IChallengeStage} from "../../../../types/IChallengeStage";
import {ModalMessage} from "../../../common/ModalMessage";
import {getBase64} from "../../../../helpers/Util";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {Testing} from "../../../common/testing/Testing";
import * as TestAPI from "../../../../../requests/TestAPI";
import {ITestResult} from "../../../../types/ITestResult";
import {TestResult} from "../../../common/testing/TestResult";
import plural from "plural-ru";
import {ITestInfo} from "../../../../types/ITestInfo";
import {IChallenge} from "../../../../types/IChallenge";
import {VideoStage} from "./VideoStage";
import {CTFStage} from "./CTFStage";
import {IChallengeStageSolution} from "../../../../types/IChallengeStageSolution";

export const StageDetails: React.FC<{challenge: IChallenge | undefined, stageId: number}> = ({challenge, stageId}) => {

  const [stage, setStage] = useState<IChallengeStage>();
  const [solution, setSolution] = useState<IChallengeStageSolution>();
  const [testingId, setTestingId] = useState("");
  const [error, setError] = useState("");
  const [hasSolution, setHasSolution] = useState(false);
  const [solutionHint, setSolutionHint] = useState(false);
  const [message, setMessage] = useState("");
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [testInfo, setTestInfo] = useState<ITestInfo>();
  const [testResult, setTestResult] = useState<ITestResult>();

  useEffect(() => {
    if (challenge?.id) {
      API.get("/challenge/api/v1/challenge/" + challenge.id + "/stage/" + stageId).then((response) => {
        setStage(response.data);
        if (response.data.type === "TEST" || response.data.type === "ISPTEST") {
          ChallengeAPI.getTestResult(challenge.id || 0, stageId, (responseResult) => {
            if (responseResult.data.startedAt) {
              setTestResult(responseResult.data);
            } else if (response.data.type === "TEST") {
              TestAPI.getInfo(response.data.taskLink, (response) => {
                setTestInfo(response.data);
              });
            }
          })
        }
      });
      API.get("/challenge/api/v1/challenge/" + challenge.id + "/stage/" + stageId + "/solution")
          .then((response) => {
            if(response.status === 200 && response.data.length > 0) {
              setSolution(response.data[0]);
              setHasSolution(true);
            }
          });
    }
  }, [stageId]);

  const handleSubmit = () => {
    setError("");
    if (stage?.type === "TASK") {
      if (!solution || !solution.solutionLink) {
        setError("Поле не должно быть пустым");
        return;
      }
    } else {
      if (!solution || !solution.b64 || !solution.name) {
        setError("Необходимо загрузить файл");
        return;
      }
    }
    API.post("/challenge/api/v1/challenge/" + challenge?.id + "/stage/" + stageId + "/solution", solution)
    .then((response) => {
      if(response.status === 200) {
        setHasSolution(true);
        setMessage("Работа отправлена");
      } else if (response.data.code === "009-022" || response.data.code === "002-007") {
        setError("Для отправки работы вы должны состоять в команде. Капитану необходимо нажать на кнопку \"Закончить формирование команды\"");
      }
      else {
        setError(`Ошибка ${response.data.code}. Пожалуйста, повторите попытку позже`);
      }
    })
  }

  const doISpringLogin = () => {
    setError("");
    API.post("/challenge/api/v1/integrations/ispring/go", {"stageId": stage?.id, "challengeId": challenge?.id, "contentItemId": stage?.taskLink}).then((response) => {
      if (response.status === 200)
        //openInNewTab(response.data);
        window.location.href = response.data;
      else
        setError("Для прохождения тестирования вы должны состоять в команде. Капитану необходимо нажать на кнопку \"Закончить формирование команды\". ");
    });
  }

  const runTest = () => {
    setError("");
    setSubmitDisabled(true);
    if (stageId && challenge?.id) {
      if (stage?.type === "TEST") {
        ChallengeAPI.runTest(challenge.id, stageId, (response) => {
          if (response.status === 200) {
            setTestingId(response.data);
          } else {
            if (response.data.code === "009-022" || response.data.code === "002-007") {
              setError("Для прохождения тестирования вы должны состоять в команде. Капитану необходимо нажать на кнопку \"Закончить формирование команды\". ");
            } else {
              setError("Ошибка при получении ссылки на тест");
            }
          }
          setSubmitDisabled(false);
        })
      } else if (stage?.type === "ISPTEST") {
        doISpringLogin();
      }
    }
  }

  const handleFileChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    setError("");
    const fileList = e.target.files;
    if (!fileList) return;
    if (fileList[0].size >= 5000000) {
      setError("Размер файла не должен превышать 5Мб.");
      return;
    }
    getBase64(fileList[0], ((b64) => {
      setSolution({...solution, b64: b64, name: fileList[0].name, solutionLink: fileList[0].name});
    }));
  };

  if (testingId && challenge?.id) {
    return (<Testing challengeId={challenge.id} stageId={stageId} testingId={testingId}/>)
  } else if (testResult) {
    return <TestResult result={testResult}/>
  } if (stage?.type === "INFO") {
    return (
        <div className="container-right-block">
          <div className="menu-challenge">
            <ModalMessage msg={message} header={"Успех"} _callback={() => setMessage("")}/>
            <h2 className="headline-right-block">{stage?.name}</h2>

            <div className="text-editor-style" dangerouslySetInnerHTML={{__html: stage?.task || ""}}></div>
          </div>
        </div>
    );
  } else if (stage?.type === "VIDEO") {
    return (<VideoStage stage={stage}/>)
  } else if (stage?.type === "CTF") {
    return (<CTFStage stage={stage}/>)
  } else {
    return (
        <div className="container-right-block">
          <div className="menu-challenge">
            <ModalMessage msg={message} header={"Успех"} _callback={() => setMessage("")}/>
            <h2 className="headline-right-block">{stage?.name}</h2>

            <div className="text-editor-style" dangerouslySetInnerHTML={{__html: stage?.task || ""}}></div>
            <fieldset className="block-input">
              <legend className="legend">Даты проведения (UTC +3)</legend>
              <div className="block-input-flex">
                <p className="input-challenges">{stage?.startDate ? new Date(stage.startDate).toLocaleDateString().replaceAll("/", ".") : "-"}</p>
                <p className="input-challenges">{stage?.endDate ? new Date(stage.endDate).toLocaleDateString().replaceAll("/", ".") : "-"}</p>
              </div>
            </fieldset>
          {stage?.type === "TASK" || stage?.type === "TASK_FILE" ? (
              <>
                <fieldset className="block-input">
                  <legend className="legend">Ссылка на задание</legend>
                  <a href={stage?.taskLink} target="_blank" className="rules">Посмотреть задание</a>
                </fieldset>
                <fieldset className="block-input">
                  <legend className="legend">{stage.type === "TASK_FILE" ? "Загрузите решение" : "Ссылка на решение"}</legend>
                  <input type="text" value={solution ? solution.solutionLink : ""}
                         onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                           setSolution({...solution, solutionLink: e.target.value});
                         }} className="input-challenges" placeholder={stage.type === "TASK_FILE" ? "" : "Вставьте ссылку"} disabled={(new Date() > new Date(stage?.endDate + "+03:00" || "")) || stage.type === "TASK_FILE"}/>
                  <div className="answer" onMouseOver={() => setSolutionHint(!solutionHint)} onMouseOut={() => setSolutionHint(!solutionHint)} style={{display: stage.type === "TASK_FILE" ? "none" : ""}}></div>
                  <p className="answer-text" style={{display: solutionHint ? "" : "none"}}>Добавьте ссылку на своё решение, размещённую на любом файлообменнике (депозитарии), если иное не указано в задании этапа. Убедитесь, что доступ по ссылке открыт для всех, а содержимое не закрыто паролем</p>
                </fieldset>

                <fieldset className="block-input" style={{display: (new Date() > new Date(stage?.endDate + "+03:00" || "")) || stage.type === "TASK" ? "none" : ""}}>
                  <input type="file" id="sign" className="load-file-hidden" onChange={handleFileChange}/>
                  <label htmlFor="sign" className="btn-blue width-btn">{hasSolution ? "Загрузить новое решение" : "Выбрать файл"}</label>
                </fieldset>
                <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : ""}}>Ошибка! {error}</p>
                <p className="success-message" id="form-validation-error" style={{display: hasSolution ? "" : "none"}}>Вы успешно отправили своё решение!<br/>
                  До конца этапа вы можете доработать своё решение и отправить его повторно. В этом случае будет рассматриваться заявка, отправленная последней</p>
                <div className="first-message" style={{display: !hasSolution && challenge?.challengeType === "COMMAND" ? "" : "none"}}>
                  <p className="city">
                    После отправки работы (решения) на любом этапе вы не сможете изменить состав своей команды или удалить её
                  </p>
                </div>
                <button className="btn-main" style={{display: new Date() > new Date(stage?.endDate + "+03:00" || "") ? "none" : ""}} onClick={handleSubmit}>{hasSolution ? "Отправить новое решение" : "Отправить"}</button>
              </>
          ) : (
              <>
                {testInfo ?
                    new Date() < new Date(stage?.endDate + "+03:00" || "") ?
                      <>
                        <p className="point-system">
                          Максимальное количество баллов за тестирование: <span className="point-system-fact">{testInfo.maxPoints}</span>
                        </p>
                        <p className="point-system">
                          Количество вопросов: <span className="point-system-fact">{testInfo.numQuestions}</span>
                        </p>
                        <p className="point-system">
                          Время на тестирование: <span className="point-system-fact">{plural(testInfo.time, "%d минута", "%d минуты", "%d минут")}</span>
                        </p>
                        <p className="point-system">
                          Даётся одна попытка. Можно возвращаться к пропущенным вопросам.
                        </p>
                        {challenge?.challengeType === "COMMAND" ?
                            <div className="first-message">
                              <p className="city">
                                После отправки теста на любом этапе вы не сможете изменить состав своей команды или удалить её
                              </p>
                            </div>
                        :
                        <></>}
                      </>
                        :
                      <>
                        <div className="first-message">
                          <p className="city">
                            Вы не проходили тестирование
                          </p>
                        </div>
                      </>
                    : //ISPRING STUFF
                    <>
                    </>
                }

                <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : ""}}>Ошибка! {error}</p>
                <button className="btn-main" style={{display: new Date() > new Date(stage?.endDate + "+03:00" || "") ? "none" : ""}} onClick={runTest} disabled={submitDisabled}>{submitDisabled ? "Подождите..." : "Пройти тестирование"}</button>
              </>
          )}
          </div>
        </div>
    );
  }
}
