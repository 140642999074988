import React, {useEffect, useState} from "react";
import {IProfilePersonalForm} from "../../types/IProfilePersonalForm";
import API from "../../../requests/_axios";
import {toNumber} from "lodash";
import * as InputMask from "react-input-mask";
import SessionModel from "../../../stores/SessionModel";
import {ProfileSettings} from "./ProfileSettings";
import {CONFIG} from "../../../CONFIG";
import cookie from "js-cookie";
import {useNavigate} from "react-router-dom";
import {ModalMessage} from "./ModalMessage";
import * as ChallengeAPI from "../../../requests/ChallengeAPI";
import {IProfilePersonalJob} from "../../types/IProfilePersonalJob";

interface IMerchSizeDict {
  name: string;
  value: string;
}

export const ProfileIndividualDetailsEditor:
    React.FC<{
      currentForm: IProfilePersonalForm | undefined,
      userId: number | null | undefined,
      clazzName?: string}>
    = ({currentForm, userId, clazzName}) => {

  const InputMask = require('react-input-mask');
  const [dadata, setDadata] = useState([]);
  const [city, setCity] = useState('');
  const [universityList, setUniversityList] = useState([]);
  const [university, setUniversity] = useState("");
  const [showCustomUniversity, setShowCustomUniversity] = useState(false);
  const [birthDate, setBirthDate] = useState<any>();
  const [tgHint, setTgHint] = useState(false);
  const [persDataHint, setPersDataHint] = useState(false);
  const [showTgHint, setShowTgHint] = useState(false);
  const [aboutHint, setAboutHint] = useState(false);
  const [form, setForm] = useState<IProfilePersonalForm>({
    firstname: "",
    lastname: "",
    patronymic: "",
    telegram: "",
    skills: "",
    phone: "",
    fias: "",
    educationStage: 1,
  });
  const [job, setJob] = useState<IProfilePersonalJob>();
  const [error, setError] = useState("");
  const [preview, setPreview] = useState<any>();
  const navigate = useNavigate();
  const [merchSizeDict, setMerchSizeDict] = useState<IMerchSizeDict[]>([]);
  const [merchHint, setMerchHint] = useState(false);
  const [universityHint, setUniversityHint] = useState(false);

  useEffect(() => {
    ChallengeAPI.updateLastSeenIndividual();

    ChallengeAPI.getMerchSizes((response) => {
      const msDict = [];
      msDict.push({"name": "Выберите из списка", "value": ""});
      if (response.status === 200) {
        for (const k in response.data) {
          msDict.push({"name": k + " (" + response.data[k] + ")", "value": k});
        }
      }
      setMerchSizeDict(msDict);
    });

    if (currentForm) {
      if(currentForm.universityName != null && currentForm.universityName.length > 0) {
        setShowCustomUniversity(true);
      }
      if(currentForm.birthDate) {
        setBirthDate(currentForm.birthDate.substring(0,10));
      }
      if(currentForm.region && currentForm.city)
        setCity(currentForm.region + ", " + currentForm.city);
      setUniversity(currentForm.university || "");
      if(!currentForm.educationStage)
        currentForm.educationStage = 0;
      setForm(currentForm);
      if (currentForm.avatar !== null)
        setPreview(CONFIG.STORAGE_URL + "/" + (userId !== null && userId !== undefined ? userId : SessionModel.getCurrentUserAuthData().id) + "/avatar/" + currentForm.avatar);
      else
        setPreview("/images/no_ava_user.svg");
      if (currentForm.jobs && currentForm.jobs.length > 0) {
        setJob({...job, orgName: currentForm.jobs[0].orgName, position: currentForm.jobs[0].position});
      }
    } else {
      let url = "/challenge/api/v1/personal/profile/" + userId;
      API.get(url, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
        if (response.status === 200) {
          response.data.educationStage = response.data.educationStage ? response.data.educationStage : 0;
          setForm(response.data);
          if (response.data.region !== null && response.data.city !== null) {
            setCity(response.data.city);
          }
          if(response.data.universityName != null && response.data.universityName.length > 0) {
            setShowCustomUniversity(true);
          }
          if(response.data.birthDate) {
            setBirthDate(response.data.birthDate.substring(0,10));
          }
          if(response.data.region && response.data.city)
            setCity(response.data.region + ", " + response.data.city);
          setUniversity(response.data.university || "");
          if (response.data.avatar !== null)
            setPreview(CONFIG.STORAGE_URL + "/" + (userId !== null && userId !== undefined ? userId : SessionModel.getCurrentUserAuthData().id) + "/avatar/" + response.data.avatar);
          else
            setPreview("/images/no_ava_user.svg");
          if (response.data.jobs.length > 0) {
            setJob({...job, orgName: response.data.jobs[0].orgName, position: response.data.jobs[0].position});
          }
        }
      });
    }
  }, [userId]);

  const fetchUniversities = (query: string) => {
    API.get("/challenge/api/v1/university/search?name=" + query, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
      if(response.status === 200)
        setUniversityList(response.data);
      else
        setUniversityList([]);
    });
  }

  const handleUniversityClick = (text: string, id: number) => {
    setUniversity(text);
    setForm({...form, universityId: id});
    setUniversityList([]);
  }

  const handleUniversityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({...form, universityId: null});
    if(event.target.value.length > 2) {
      fetchUniversities(event.target.value);
    }
    setUniversity(event.target.value);
  }

  const handleUniversityNotFound = () => {
    if(showCustomUniversity) {
      setForm({...form, universityName: "", universityType: 0});
      setShowCustomUniversity(false);
    } else {
      setForm({...form, universityId: null});
      setShowCustomUniversity(true);
    }
  }

  const validateAll = () : boolean => {
    let k: keyof typeof form;
    for (k in form) {
      if(k == "phone" && form[k]) {
        form[k] = form[k].replace(/\D/g,'');
      }
      if(k == "city" && !form.fias) {
        setCity("");
        setError("Выберите город из списка");
        return false;
      }
      if(k == "university" && !showCustomUniversity && !form.universityId) {
        setUniversity("");
        setError("Если вы не находите своё учебное заведение в списке, значит оно ещё не зарегистрировано на платформе. Пожалуйста, выберите пункт \"Нет моего учебного заведения\" и добавьте информацию о нём вручную");
        return false;
      }
      // if (k == "educationStage" && (!form[k] || form[k] === 0)) {
      //   setError("Выберите ступень образования");
      //   return false;
      // }
    }
    return true;
  }

  /*  const displayValidationError = (text: string) => {
      setError(text);
      // @ts-ignore
      document.querySelector('.right-menu').scrollTo(0,0);
    }*/

  const handleUpdate = () => {
    let url = "/challenge/api/v1/personal/profile";
    if(userId !== null && userId !== undefined) {
      url = url + "/" + userId;
    }
    if(validateAll()) {
      form.jobs = job ? new Array(job) : [];
      API.put(url, form, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
        if(response.status === 200) {
          if (cookie.get("afterLoginRedirect")) {
            navigate(cookie.get("afterLoginRedirect") || "");
          } else {
            window.location.reload();
          }
        } else if(response.status == 400) {
          setError(response.data.message);
        } else {
          setError("Ooops... Что-то не сработало :) Попробуйте еще раз, или свяжитесь с администрацией");
        }
      });
    }
  }

  const handleAvatarChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    setError("");
    const fileList = e.target.files;
    if (!fileList) return;
    if (fileList[0].size >= 5000000) {
      setError("Размер изображения не должен превышать 5Мб.");
      return;
    }
    const formData = new FormData();
    formData.append("file", fileList[0], fileList[0].name);
    API.post("/challenge/api/v1/personal/profile/avatar", formData, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
      if(response.status === 200) {
        setPreview(CONFIG.STORAGE_URL + "/" + SessionModel.getCurrentUserAuthData().id + "/avatar/" + response.data.avatar);
        setForm({...form, avatar: response.data.avatar});
      } else {
        setError("Изображение должно быть в формате JPG и размер не должен превышать 5 Мб.")
      }
    });
  };

  const handleCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({...form, fias: ""});
    if(event.target.value.length > 2) {
      fetchLocation(event.target.value);
    }
    setCity(event.target.value);
  }

  const fetchLocation = (query: string) => {
    API.get("/challenge/api/v1/location/city?city=" + query, {headers: {"Authorization": "Bearer " + SessionModel.getToken()}}).then((response) => {
      if(response.status === 200)
        setDadata(response.data);
      else
        setDadata([]);
    });
  };

  const handleCityClick = (text: string, fias: string) => {
    setCity(text);
    setForm({...form, fias: fias});
    setDadata([]);
  }

  const handleChangeBirthDate = function (e: React.ChangeEvent<HTMLInputElement>) {
    setForm({...form, birthDate: e.target.value});
    setBirthDate(e.target.value);
  }

  return (
      <>
        <div className={clazzName || "right-menu"}>
          <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
          <p className="error-message" id="form-validation-error" style={{display: error === "" ? "none" : ""}}>Ошибка! {error}</p>
          <p className="message-for-sure">
            * — отмеченные этим знаком поля являются обязательными для заполнения
          </p>
          <div className="avatar-right-block">
            <label htmlFor="addimage" className="addpost-btn">
              <img src={preview} onError={({currentTarget}) => {currentTarget.onerror = null; currentTarget.src="/images/no_ava_user.svg"}} alt="" className="avater-user"/>
              <input type="file" name="" accept=".jpg,.jpeg" id="addimage" onChange={handleAvatarChange} style={{display: 'none' }}/>
              <div className="user-info">
                <p className="fio-or-name">
                  Ваш аватар
                </p>
              </div>
            </label>
          </div>
          <fieldset className="block-input" >
            <legend className="legend">ФИО*</legend>
            <input type="text" value={form.lastname || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
              setForm({...form, lastname: e.target.value});
            }} className="input-challenges" placeholder="Фамилия" disabled={!SessionModel.isAdmin() && !form.isEditableName}/>
            <input type="text" value={form.firstname || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
              setForm({...form, firstname: e.target.value});
            }} className="input-challenges" placeholder="Имя" disabled={!SessionModel.isAdmin() && !form.isEditableName}/>
            <input type="text" value={form.patronymic || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
              setForm({...form, patronymic: e.target.value});
            }} className="input-challenges" placeholder="Отчество" disabled={!SessionModel.isAdmin() && !form.isEditableName}/>
          </fieldset>
          <fieldset className="block-input-flex" >
            <legend className="legend">Пол*</legend>
            <label className="input-challenges-flex">
              <input type="radio" name="sex" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setForm({...form, sex: toNumber(e.target.value)})
              }} value={1} className="radio-btn" checked={form.sex == 1}/>
              <span className="span-radio">Мужской</span>
            </label>
            <label className="input-challenges-flex">
              <input type="radio" name="sex" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setForm({...form, sex: toNumber(e.target.value)})
              }} value={2} className="radio-btn" checked={form.sex == 2}/>
              <span className="span-radio">Женский</span>
            </label>
          </fieldset>
          <fieldset className="block-input" disabled={true}>
            <legend className="legend">Регистрационный E-mail</legend>
            <input type="text" value={form.username || ""} className="input-challenges" placeholder="E-mail"/>
          </fieldset>
          <fieldset className="block-input" >
            <legend className="legend">Логин Telegram*</legend>
            <input type="text" value={form.telegram || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
              setForm({...form, telegram: e.target.value});
            }} className="input-challenges" maxLength={64} placeholder="Shalashsi"/>
            <div className="answer" onMouseOver={() => setTgHint(!tgHint)} onMouseOut={() => setTgHint(!tgHint)}></div>
            <p className="answer-text" style={{display: tgHint ? "" : "none"}}>Общение пользователей платформы происходит в Telegram. Найти свой логин вы можете, выбрав в Telegram: Меню - Настройки - Имя пользователя (Username). Если у вас пока нет имени пользователя, необходимо его создать</p>
          </fieldset>
          <fieldset className="block-input" >
            <legend className="legend">Дата рождения*</legend>
            <input type="date" value={birthDate} onChange={handleChangeBirthDate} className="input-challenges" min="1700-01-01" max="2100-01-01"/>
          </fieldset>
          <fieldset className="block-input" >
            <legend className="legend">Город*</legend>
            <input type="text" onChange={handleCity} value={city} className="input-challenges city-search"
                   placeholder="Начните вводить и выберите значение из выпадающего списка"/>
            <div className="container-city">
              {dadata && dadata.map((d, i) =>
                  //@ts-ignore
                  <div onClick = {() => handleCityClick(d.label, d.id)} className="city-input" key={i}>{d.label}</div>
              )}
            </div>
          </fieldset>
          <fieldset className="block-input-flex" >
            <legend className="legend">Статус*</legend>
            <label className="input-challenges-flex">
              <input type="radio" name="status" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setForm({...form, personStatus: toNumber(e.target.value)})
              }} value={1} className="radio-btn" checked={form.personStatus == 1}/>
              <span className="span-radio">Студент</span>
            </label>
            <label className="input-challenges-flex">
              <input type="radio" name="status" onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setForm({...form, personStatus: toNumber(e.target.value)})
              }} value={2} className="radio-btn" checked={form.personStatus == 2}/>
              <span className="span-radio">Выпускник</span>
            </label>
          </fieldset>
          {showCustomUniversity ? (<>
            <fieldset className="block-input" >
              <legend className="legend">Учебное заведение*</legend>
              <input type="text" value={form.universityName || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                setForm({...form, universityName: e.target.value});
              }} className="input-challenges" placeholder="Введите название вручную"/>
              <div className="answer" onMouseOver={() => setUniversityHint(!universityHint)} onMouseOut={() => setUniversityHint(!universityHint)}/>
              <p className="answer-text" style={{display: universityHint ? "" : "none"}}>Вы можете указать образовательную организацию, которую вы закончили или в которой вы работаете. Т. е. ту, к которой вы имеете отношение и/или с которой вы хотите ассоциировать ваши успехи</p>
            </fieldset>
            <fieldset className="block-input">
              <legend className="legend">Тип учебного заведения*</legend>
              <label className="input-challenges-flex">
                <input type="radio" name="comand_num" value="1" checked={form.universityType === 1} onChange={() => setForm({...form, universityType: 1})} className="radio-btn"/>
                <span className="span-radio">Колледж / Техникум</span>
              </label>
              <label className="input-challenges-flex">
                <input type="radio" name="comand_num" value="2" checked={form.universityType === 2} onChange={() => setForm({...form, universityType: 2})} className="radio-btn"/>
                <span className="span-radio">Вуз</span>
              </label>
              <a href="#0" onClick={() => handleUniversityNotFound()} className="link-no">Выбрать учебное заведение из списка</a>
            </fieldset>
          </>) : (<>
            <fieldset className="block-input" >
              <legend className="legend">Учебное заведение*</legend>
              <input type="text" onChange={handleUniversityChange} value={university || ""} className="input-challenges city-search" placeholder="Начните вводить и выберите из списка"/>
              <div className="container-city">
                {universityList && universityList.map((d, i) =>
                    //@ts-ignore
                    <div onClick = {() => handleUniversityClick(d.fullName, d.userId)} className="city-input" key={i}>{d.fullName}</div>
                )}
                <a href="#0" className="link-no" onClick={() => handleUniversityNotFound()}>Нет моего учебного заведения</a>
              </div>
            </fieldset>
          </>)}

          <fieldset className="block-input" style={form.personStatus === 2 ? {"display": "none"} : {}}>
            <legend className="legend">Ступень образования</legend>
            <select value={form.educationStage} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setForm({...form, educationStage: toNumber(e.target.value)})
            }} className="input-challenges-select">
              <option value={0}>Выберите из списка</option>
              <option value={1}>Бакалавриат</option>
              <option value={2}>Специалитет</option>
              <option value={3}>Магистратура</option>
              <option value={4}>Аспирантура</option>
              <option value={5}>Другая</option>
            </select>
          </fieldset>
          <fieldset className="block-input" style={form.personStatus === 2 ? {"display": "none"} : {}}>
            <legend className="legend">Факультет</legend>
            <input type="text" value={form.faculty} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setForm({...form, faculty: e.target.value})
            }} className="input-challenges" placeholder="Факультет"/>
          </fieldset>
          <fieldset className="block-input" style={form.personStatus === 2 ? {"display": "none"} : {}}>
            <legend className="legend">Курс (если вы обучаетесь)</legend>
            <input type="number" value={String(form.course).replace(/^0+/, '')} onScroll={(e) => e.preventDefault()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setForm({...form, course: toNumber(e.target.value)})
            }} className="input-challenges" placeholder="Введите курс" min={1} max={7}/>
          </fieldset>
          <fieldset className="block-input">
            <legend className="legend">Место работы</legend>
            <input type="text" value={job?.orgName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setJob({...job, orgName: e.target.value})
            }} className="input-challenges" placeholder="Название организации"/>
          </fieldset>
          <fieldset className="block-input">
            <legend className="legend">Должность</legend>
            <input type="text" value={job?.position} onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setJob({...job, position: e.target.value})
            }} className="input-challenges" placeholder="Занимаемая должность"/>
          </fieldset>
          <fieldset className="block-input" >
            <legend className="legend">Навыки (через запятую)*</legend>
            <input type="text" value={form.skills || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
              setForm({...form, skills: e.target.value});
            }} className="input-challenges" placeholder="Например: программирование, java, python"/>
          </fieldset>
          <fieldset className="block-input" >
            <legend className="legend">Телефон*</legend>
            <InputMask mask="+999999999999999" value={form.phone || ""} className="input-challenges"
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                       {setForm({...form, phone: e.target.value});
                       }}/>
            {/*<input type="text" value={form.phone || ""} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {*/}
            {/*  setForm({...form, phone: e.target.value});*/}
            {/*}} className="input-challenges" placeholder="Начните вводить"/>*/}
          </fieldset>
          <fieldset className="block-input">
            <legend className="legend">Немного о себе</legend>
            <textarea value={form.about} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>)=> {
              setForm({...form, about: e.target.value});
            }} cols={30} rows={10} className="input-challenges" placeholder="Введите текст не более 500 символов" maxLength={500}/>
            <div className="answer" onMouseOver={() => setAboutHint(!aboutHint)} onMouseOut={() => setAboutHint(!aboutHint)}></div>
            <p className="answer-text" style={{display: aboutHint ? "" : "none"}}>Укажите в этом разделе информацию о своих профессиональных достижениях, интересах, личных качествах, а также другие важные, на ваш взгляд, сведения. Это поможет другим участникам лучше узнать вас и увеличит ваши шансы найти участников в свою команду или присоединиться к уже существующей команде</p>
          </fieldset>
          <fieldset className="block-input">
            <legend className="legend">Размер одежды</legend>
            <select value={form.merchSize} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
              setForm({...form, merchSize: e.target.value})
            }} className="input-challenges-select">
              {
                merchSizeDict.map((ms) =>
                    <option value={ms.value}>{ms.name}</option>
                )
              }
            </select>
            <div className="answer" onMouseOver={() => setMerchHint(!merchHint)} onMouseOut={() => setMerchHint(!merchHint)}></div>
            <p className="answer-text" style={{display: merchHint ? "" : "none"}}>Организаторы конкурсов могут выдавать участникам брендированную одежду. Заполнение данного поля не даёт 100% гарантию, но значительно увеличивает ваши шансы получить именно ваш размер</p>
          </fieldset>
          <fieldset className="block-input">
            <legend className="legend">Конфиденциальность</legend>
            <label className="input-challenges-flex">
              <input type="checkbox" name="status" checked={form.publishTelegram} onChange={() => setForm({...form, publishTelegram: !form.publishTelegram})} className="radio-btn"/>
              <span className="span-radio">Разрешить отображать логин в Telegram для других участников</span>
              <div className="answer" onMouseOver={() => setShowTgHint(!showTgHint)} onMouseOut={() => setShowTgHint(!showTgHint)}></div>
              <p className="answer-text" style={{display: showTgHint ? "" : "none"}}>Отображение логина позволит другим участникам связаться с вами, чтобы пообщаться до объединения в команду. Обратите внимание, что члены вашей команды в любом случае будут видеть ваш логин Telegram</p>
            </label>
          </fieldset>
          <fieldset className="block-input">
            <label className="input-challenges-flex">
              <input type="checkbox" name="status" checked={form.transferPersonalAgreement} onChange={() => setForm({...form, transferPersonalAgreement: !form.transferPersonalAgreement})} className="radio-btn"/>
              <span className="span-radio">Показывать мои данные представителю моего учебного заведения</span>
              <div className="answer" onMouseOver={() => setPersDataHint(!persDataHint)} onMouseOut={() => setPersDataHint(!persDataHint)}></div>
              <p className="answer-text" style={{display: persDataHint ? "" : "none"}}>Если вы отметили этот пункт, то зарегистрированный представитель вашего учебного заведения сможет видеть информацию, указанную вами в профиле, и результаты вашего участия в мероприятиях. Это позволит получить дополнительную поддержку от учебного заведения</p>
            </label>
          </fieldset>
          <fieldset className="block-input-flex-column">
            <legend className="legend">Наличие ограничений по здоровью*</legend>
            {/*<label className="input-challenges-flex">
              <input type="radio" name="disability" value={2} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                setForm({...form, disability: toNumber(e.target.value)});
              }} className="radio-btn" checked={form.disability === 2}/>
              <span className="span-radio">Есть инвалидность</span>
            </label>*/}
            <label className="input-challenges-flex">
              <input type="radio" name="disability" value={1} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                setForm({...form, disability: toNumber(e.target.value)});
              }} className="radio-btn" checked={form.disability === 1}/>
              <span className="span-radio">Нет</span>
            </label>
            <label className="input-challenges-flex">
              <input type="radio" name="disability" value={3} onChange={(e: React.ChangeEvent<HTMLInputElement>)=> {
                setForm({...form, disability: toNumber(e.target.value)});
              }} className="radio-btn" checked={form.disability === 3}/>
              <span className="span-radio">Есть</span>
            </label>
          </fieldset>
          <button className="btn-main" onClick={handleUpdate}>Сохранить</button>

          {currentForm ? <ProfileSettings profile={currentForm}/> : <></>}
        </div>
      </>)
}