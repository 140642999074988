import React, {useState} from "react";
import {IJury} from "../../../../types/jury/IJury";
import {IChallengeStage} from "../../../../types/IChallengeStage";
import {IJuryStage} from "../../../../types/jury/IJuryStage";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {useParams} from "react-router-dom";
import {toNumber} from "lodash";
import {ModalMessage} from "../../../common/ModalMessage";

export const JuryStage:React.FC<{
    close: () => void,
    jury: IJury,
    stages: IChallengeStage[]}> = ({close, jury,stages}) => {

    const [juryStages, setJuryStages] = useState<IJuryStage[]>(jury.juryStages);
    const [error, setError] = useState("");
    const params = useParams();

    const isChosen = (id: number | undefined) : boolean => {
        return juryStages.some(s => s.stageId === id);
    }

    const pickStage = (id: number | undefined) => {
        if (id) {
            let newStages: IJuryStage[];
            newStages = juryStages.filter(cs => cs.stageId !== id);
            console.log(newStages);
            if (newStages.length == juryStages.length) {
                const newStage: IJuryStage = {
                    juryId: jury.id,
                    stageId: id
                }
                newStages.push(newStage);
                console.log("remove");
            }
            setJuryStages(newStages);
        }

    }

    const updateStages = () => {
        const ids = juryStages.map(js => (js.stageId));
        ChallengeAPI.updateJuryStages(toNumber(params.id), jury.id, ids, (response) => {
            if (response.status === 200) {
                window.location.reload();
            } else {
                setError("Ошибка при обновлении этапов");
            }
        });
    }

    return (
        <div className="right-block">
            <header className="header">
                <p className="header-back" id="backmenu" onClick={close}>Назад</p>
                <p className="name-menu">Этапы</p>
            </header>

            <div className="container-right-block">
                <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                <div className="menu-challenge">
                    <h2 className="headline-right-block">{`${jury.profile.lastname} ${jury.profile.firstname}`}</h2>
                    <p className="challenges-description">
                        Выберите этапы, которые будет оценивать член жюри
                    </p>
                    {
                        stages.map((s, index) =>
                            <div className="role-wrapper" key={index} style={{cursor: "pointer"}} onClick={() => pickStage(s.id)}>
                                <p className="input-role">{s.name}</p>
                                {isChosen(s.id) ? <button className="btn-ok"/> : <button className="btn-non-check"/>}
                            </div>
                        )
                    }
                </div>
                <input type="button" className="btn-main" value="Сохранить" onClick={updateStages}/>
            </div>
        </div>
    )
}