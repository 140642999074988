import React, {useEffect, useState} from "react";
import {useParams, useSearchParams} from "react-router-dom";
import API from "../../../../../requests/_axios";
import {IChallenge} from "../../../../types/IChallenge";
import {IChallengeStage} from "../../../../types/IChallengeStage";
import {ChallengeSettings} from "../right/ChallengeSettings";
import {StageSettings} from "../right/StageSettings";
import {ModalMessage} from "../../../common/ModalMessage";
import {OrgHeader} from "../../../common/OrgHeader";
import {OrgNavigation} from "../../../common/OrgNavigation";
import {ChallengeBranding} from "../right/ChallengeBranding";
import {JuryTab} from "../assessment/JuryTab";
import {CONFIG} from "../../../../../CONFIG";
import {StageButtonAdmin} from "./StageButtonAdmin";
import SessionModel from "../../../../../stores/SessionModel";
import {toNumber} from "lodash";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {IExternalIntegration} from "../../../../types/IExternalIntegration";

export const ChallengeTab = () => {

  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState("settings");
  const [currentStageId, setCurrentStageId] = useState<any>(null);
  const [challenge, setChallenge] = useState<IChallenge>();
  const [stages, setStages] = useState<any>([]);
  const [showSettings, setShowSettings] = useState(false);
  const [showBranding, setShowBranding] = useState(false);
  const [showStage, setShowStage] = useState(false);
  const [message, setMessage] = useState("");
  const [externalIntegrations, setExternalIntegrations] = useState([]);
  const [bottom, setBottom] = useState<HTMLDivElement | null>();

  useEffect(() => {
    //setTab(searchParams.get("tab") || "settings");
    fetchChallenge();
    fetchIntegrations();
  }, []);

  useEffect(() => {
    if (challenge) {
      setTab(searchParams.get("tab") || "settings");
    }
  }, [challenge]);

  useEffect(() => {
    if (showSettings) {
      setShowStage(false);
      setCurrentStageId(null);
      setShowBranding(false);
    }
  }, [showSettings]);

  useEffect(() => {
    if (showBranding) {
      setShowSettings(false);
      setShowStage(false);
      setCurrentStageId(null);
    }
  }, [showBranding]);

  useEffect(() => {
    if (currentStageId) {
      setShowBranding(false);
      setShowSettings(false);
      setShowStage(true);
    } else {
      setShowStage(false);
    }
  }, [currentStageId]);

  const fetchChallenge = () => {
    API.get("/challenge/api/v1/challenge/" + params.id).then((response) => {
      if (response.status === 200) {
        setChallenge(response.data);
        if(response.data.stages.length !== 0)
          setStages(response.data.stages);
      } else if (response.status === 404) {
        window.location.href = "/404";
      }
    })
  }

  const fetchIntegrations = () => {
    ChallengeAPI.getExternalIntegrations((response) => {
      if (response.status === 200) {
        setExternalIntegrations(response.data);
      }
    });
  }

  const handleCreateStage = (type: string) => {
    showStageTypePopup();
    API.post(`/challenge/api/v1/challenge/${challenge?.id}/stage/${type}`).then((response => {
      const updatedStagesList = [...stages, response.data];
      setStages(updatedStagesList);
      bottom?.scrollIntoView({behavior: "smooth"})
    }));
  }

  const handleSaveStage = () => {
    setCurrentStageId(null);
    // @ts-ignore
    document.querySelector('#right-block-stage').style.right = "-100vw";
    fetchChallenge();
  }

  const changeShowStage = (id: number | undefined | null) => {
    setCurrentStageId(id);
    if (id) {setShowStage(true); }
  }

  const handleSaveChallenge = () => {
    fetchChallenge();
    setShowSettings(false);
  }

  const handleUploadAvatar = (id: number | undefined, src: string) => {
    // @ts-ignore
    document.querySelector('#challenge-avatar').src = src;
    //fetchChallenges();
  }

  const handlePublish = () => {
    if (challenge) {
      if (challenge.isPublished && !confirm("Вы уверены? Все текущие участники мероприятия потеряют к нему доступ"))
        return;
      API.post("/challenge/api/v1/challenge/" + params.id + "/publish").then((response) => {
        if(response.status === 200) {
          setChallenge({...challenge, isPublished: !challenge.isPublished});
        } else {
          setMessage(response.data.message);
        }
      });
    }
  }

  const showStageTypePopup = () => {
    // @ts-ignore
    if(document.querySelector(".button-add-popup").style.display == 'none') {
      // @ts-ignore
      document.querySelector(".button-add-popup").style.display = 'flex';
    } else {
      // @ts-ignore
      document.querySelector(".button-add-popup").style.display = 'none';
    }
  }

  const handleDelete = (stageId: number) => {
    if(window.confirm(`Вы действительно желаете удалить этап?`)) {
      API.delete("/challenge/api/v1/challenge/" + params.id + "/stage/" + stageId).then((response) => {
        if(response.status === 200) {
          window.location.reload();
        } else {
          if (response.data.code === "009-031") {
            setMessage("Удалить нельзя. Есть решения или пройденные тесты");
          } else if (response.data.code === "009-034") {
            setMessage("Тарифный план закончился");
          } else {
            // @ts-ignore
            document.querySelector('.container-right-block').scrollTo(0,0);
          }
        }
      });
    }
  }

  if (challenge === undefined) {
    return (<></>)
  } else if (tab === "settings") {
    return (
        <div className="platform-background">
          <ModalMessage msg={message} header={"Ошибка"} _callback={() => setMessage("")}/>
            {challenge?.branding && challenge.branding.backgroundPath ?
                challenge.branding.backgroundPath.split('.').pop() === "jpg" ?
                    <img src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath} className="image-back"/>
                    :
                    <video autoPlay muted loop playsInline className="image-back-video">
                      <source src={CONFIG.STORAGE_URL + "/" + challenge.branding.backgroundPath}/>
                    </video>
                :
                <video autoPlay muted loop playsInline className="image-back-video">
                  <source src={require("../../../../../../public/images/main_video.mp4").default}/>
                </video>
            }
          <div className="left-block">
            <OrgHeader preview={null}/>
            <main className="content">
              <OrgNavigation />
              <div className="main-list-center">
                <button className="button-add" onClick={showStageTypePopup}>
                  Добавить этап
                </button>

                <div className="button-add-popup" style={{display: 'none'}}>
                  <button className="add-stage" onClick={() => handleCreateStage("TEST")}>Тестирование</button>
                  <button className="add-stage" onClick={() => handleCreateStage("TASK")}>Задание (ссылка)</button>
                  <button className="add-stage" onClick={() => handleCreateStage("TASK_FILE")}>Задание (файл)</button>
                  <button className="add-stage" onClick={() => handleCreateStage("INFO")}>Инфо</button>
                  <button className="add-stage" onClick={() => handleCreateStage("VIDEO")}>Обучение</button>
                  <button className="add-stage" onClick={() => handleCreateStage("CTF")}>Флаги (CTF)</button>
                  {externalIntegrations?.map((integration: IExternalIntegration, key) =>
                      <button className="add-stage" onClick={() => handleCreateStage(integration.type || "")} key={key}>{integration.name}</button>
                  )}
                </div>

                <div className="my-challenge">
                  <div className="user-info">
                    <p className="fio-or-name">{challenge?.name}</p>
                  </div>
                  <ul className="nav-block-center">
                    <li className="nav-center-item">
                      <a href="?tab=settings" className={tab === "settings" ? "link-nav-center-item" : "link-nav-center-item non-active-nav"}>Настройки</a>
                    </li>
                    <li className="nav-center-item">
                      <a href="?tab=jury" className="link-nav-center-item non-active-nav">Эксперты</a>
                    </li>
                  </ul>
                </div>
                <div className="challenge-info">
                  <div className="block-settings-challenge">
                    <button className="settings-challenge-button" onClick={() => setShowSettings(true)}>Основные настройки</button>
                  </div>
                  {stages?.map((s: IChallengeStage, i: number) =>
                      <StageButtonAdmin stage={s} showStage={changeShowStage} deleteStage={handleDelete} key={i}/>
                  )}
                  <div ref={(el) => { setBottom(el); }}></div>
                  {toNumber(SessionModel.getCurrentUserAuthData().id) === challenge.userId ?
                      <button className="btn-main" onClick={() => setShowBranding(true)}>Оформление и лендинг</button>
                      :
                      <></>
                  }
                  <button className="btn-no" onClick={handlePublish}>{challenge?.isPublished ? ("Снять с публикации")  : ("Опубликовать")}</button>
                </div>
              </div>
            </main>
          </div>



          <div className="right-block" style={{right: showSettings ? "0" : "-100vw"}}>
            <header className="header">
              <p className="header-back" id="backmenu" onClick={() => setShowSettings(false)}>Назад</p>
              <p className="name-menu">Данные мероприятия</p>
            </header>
            {showSettings ? (
                <>
                  <ChallengeSettings challengeId={challenge?.id} handleSave={handleSaveChallenge} handleUploadAvatar={handleUploadAvatar}/>
                </>
            ) : (<></>)}
          </div>
          <div className="right-block" id="right-block-stage" style={{right: showStage ? "0" : "-100vw"}}>
            <header className="header">
              <p className="header-back" id="backmenu" onClick={() => setShowStage(false)}>Назад</p>
              <p className="name-menu">Данные этапа</p>
            </header>
            {currentStageId !== null ? (
                <>
                  <StageSettings key={currentStageId} stageId={currentStageId} challengeId={challenge?.id} handleSave={handleSaveStage}></StageSettings>
                </>
            ) : (<></>)}
          </div>
          <div className="right-block" id="right-block-branding" style={{right: showBranding ? "0" : "-100vw"}}>
            <header className="header">
              <p className="header-back" id="backmenu" onClick={() => setShowBranding(false)}>Назад</p>
              <p className="name-menu">Оформление</p>
            </header>
            {showBranding ? (
                <>
                  <ChallengeBranding challenge={challenge}/>
                </>
            ) : (<></>)}
          </div>
        </div>
    )
  } else {
    return <JuryTab challenge={challenge}/>
  }

}