import React from "react";
import SessionModel from "../../../stores/SessionModel";
import {ProfileNavigationAdmin} from "../admin/ProfileNavigationAdmin";
import {NavigationUniversity} from "../university/NavigationUniversity";
import {NavigationBusiness} from "../business/NavigationBusiness";

export const OrgNavigation = () => {

    if (SessionModel.isAdmin()) {
        return <ProfileNavigationAdmin/>
    } else if (SessionModel.isOrg()) {
        if (SessionModel.isBsn()) {
            return <NavigationBusiness/>
        } else {
            return <NavigationUniversity/>
        }
    } else {
        return <></>
    }
}