import {ProfileUniversityHeader} from "../../university/ProfileUniversityHeader";
import {NavigationUniversity} from "../../university/NavigationUniversity";
import React, {useEffect, useState} from "react";
import {PlanHeader} from "./PlanHeader";
import * as WalletAPI from "../../../../requests/WalletAPI";
import {ModalMessage} from "../ModalMessage";
import {CardPayment} from "./right/CardPayment";
import {BillPayment} from "./right/BillPayment";
import {toNumber} from "lodash";
import SessionModel from "../../../../stores/SessionModel";
import {ProfileBusinessHeader} from "../../business/ProfileBusinessHeader";
import {NavigationBusiness} from "../../business/NavigationBusiness";

export const WalletTab = () => {

    const [balance, setBalance] = useState(0);
    const [error, setError] = useState("");
    const [showCardPayment, setShowCardPayment] = useState(false);
    const [showBillPayment, setShowBillPayment] = useState(false);

    useEffect(() => {
        WalletAPI.getBalance((response) => {
            if (response.status === 200) {
                setBalance(response.data);
            } else {
                setError("Ошибка получения кошелька");
            }
        })
    }, []);

    const showCardPanel = () => {
        setShowBillPayment(false);
        setShowCardPayment(true);
    }

    const showBillPanel = () => {
        setShowCardPayment(false);
        setShowBillPayment(true);
    }

    return (
        <div className="platform-background">
            <video autoPlay muted loop playsInline className="image-back-video">
                <source src={require("../../../../../public/images/main_video.mp4").default}/>
            </video>
            <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
            <div className="left-block">
                {SessionModel.isBsn() ? <ProfileBusinessHeader newPreview={null}/> : <ProfileUniversityHeader newPreview={null}/>}
                <main className="content">
                    {SessionModel.isBsn() ? <NavigationBusiness/> : <NavigationUniversity />}
                    <div className="main-list-center">
                        <PlanHeader/>
                        <div className="challenge-info">
                            {!SessionModel.isAdmin() ?
                                <div className="first-message">
                                    <p className="city">
                                        <a href="https://disk.yandex.ru/d/h5h1ikVGh7PAsg" target="_blank" className="active-link">Видеоинструкции</a> по работе с сервисом "Оплата"
                                    </p>
                                </div>
                                :
                                <></>
                            }
                            <div className="stage-button balance-green" style={{cursor: "default"}}>
                                <h2 className="headline-balance">
                                    {toNumber(balance) / 100}
                                </h2>
                                {/*<div className="btn-info balance-green">
                                    <h4 className="btn-info-heading-green">
                                        Полный доступ
                                    </h4>
                                    <p className="btn-info-text">
                                        Вашего баланса хватает для использования всех
                                        возможностей выбранного тарифа
                                    </p>
                                </div>*/}
                                <div className="flex-button-pay">
                                    <a className="btn-pay-invoice" onClick={showBillPanel} style={{cursor: "pointer"}}>Запросить счёт</a>
                                    <a className="btn-pay-invoice" onClick={showCardPanel} style={{cursor: "pointer"}}>Оплатить картой</a>
                                </div>
                            </div>

                            {/*<div className="stage-button">
                                <h5 className="stage-card-heading">Реквизиты</h5>
                                <div className="btn-info">
                                    <h4 className="btn-info-heading">
                                        Внимание
                                    </h4>
                                    <p className="btn-info-text">
                                        При измении реквизитов компании будьте внимательны. Документы
                                        для оплаты поступают на реквизиты указанные в системе
                                    </p>
                                </div>
                                <div className="flex-button-pay">
                                    <a className="btn-pay-invoice">Измнеиить реквизиты</a>
                                    <a className="btn-pay-invoice">Скопировать реквизиты</a>
                                </div>
                            </div>*/}
                        </div>
                    </div>
                </main>
            </div>
            <CardPayment visible={showCardPayment} setVisible={setShowCardPayment}/>
            <BillPayment visible={showBillPayment} setVisible={setShowBillPayment}/>
        </div>
    )
}