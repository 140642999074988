import React, {useEffect, useState} from "react";
import API from "../../../../../requests/_axios";
import {ITeamAd} from "../../../../types/ITeamAd";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";

export const Ads: React.FC<{challengeId: number | undefined,
  updateCurrentAd: (ad: ITeamAd | undefined) => void,
  enabled: boolean}> = ({challengeId, updateCurrentAd, enabled}) => {

  const [ads, setAds] = useState([]);

  useEffect(() => {
    ChallengeAPI.getChallengeById(challengeId || 0, (response) => {
      if (response.status === 200) {
        if (response.data.challengeType === "INDIVIDUAL") {
          window.location.href=`/challenge/${challengeId}/my`;
        }
      }
    })
    fetchAds();
  }, []);

  const fetchAds = () => {
    API.get("/challenge/api/v1/challenge/" + challengeId + "/ad/my").then((response) => {
      if(response.status === 200)
        setAds(response.data);
    });
  }

  const deleteAd = (id: number | undefined) => {
    API.delete("/challenge/api/v1/challenge/" + challengeId + "/ad/" + id + "/my").then((response) => {
      if(response.status === 200)
        fetchAds();
    });
  }

  if(enabled) {
    return (
        <div className="challenge-info">
          <div className="first-message" style={{display: ads.length === 0 ? "" : "none"}}>
            <p className="fio-or-name">
              У вас ещё нет объявлений
            </p>
            <p className="city">
              Создайте объявления о наборе, чтобы привлечь в команду участников с конкретными навыками. <a href="https://youtu.be/B6UIhVP2n_s" target="_blank" className="active-link">Видеоинструкция</a>
            </p>
          </div>
          <div className="first-message" style={{display: ads.length > 0 ? "" : "none"}}>
            <p className="city">
              <a href="https://youtu.be/B6UIhVP2n_s" target="_blank" className="active-link">Видеоинструкция</a>
            </p>
          </div>
          {ads && ads.map((ad: ITeamAd, i) =>
            <li key={i} className="challenge-card">
              <p className="challenge-header" onClick={() => updateCurrentAd(ad)}>
                {ad.name}
              </p>
              <p className="challenge-description">
                {ad.description}
              </p>
              <div className="challenge-information">
                <a href={void(0)} onClick={() => updateCurrentAd(ad)} className="challenge-activity">
                  Редактировать
                </a>
                <a href={void(0)} onClick={() => deleteAd(ad.id)} className="challenge-activity-no">
                  Удалить
                </a>
              </div>
            </li>
          )}


          <button className="btn-blue" onClick={() => updateCurrentAd(undefined)}>Создать объявление</button>

        </div>
    )
  } else {
    return (<></>)
  }
}