import React, {useEffect, useState} from "react";
import {ModalMessage} from "../../../common/ModalMessage";
import {getBase64} from "../../../../helpers/Util";
import {IChallengeBranding} from "../../../../types/IChallengeBranding";
import * as ChallengeAPI from "../../../../../requests/ChallengeAPI";
import {useParams} from "react-router-dom";
import {toNumber} from "lodash";
import * as LandingAPI from "../../../../../requests/LandingAPI";
import {CONFIG} from "../../../../../CONFIG";
import {IChallenge} from "../../../../types/IChallenge";

interface ILanding {
    landingExists?: boolean;
    landingPublished?: boolean;
}
export const ChallengeBranding:React.FC<{challenge: IChallenge}> = ({challenge}) => {

    const [error, setError] = useState("");
    const [branding, setBranding] = useState<IChallengeBranding>();
    const [landing, setLanding] = useState<ILanding>();
    const params = useParams();

    useEffect(() => {
        ChallengeAPI.getChallengeBranding(toNumber(params.id), (response) => {
           if (response.status === 200) {
               setBranding(response.data);
           } else {
               setError("Ошибка загрузки данных");
           }
        });
        LandingAPI.getLanding(toNumber(params.id), (response) => {
            if (response.status === 200) {
                setLanding({landingExists: true, landingPublished: response.data.isPublished})
            } else {
                setLanding({landingExists: false, landingPublished: false});
            }
        })
    }, []);

    const handleFileChange = function (e: React.ChangeEvent<HTMLInputElement>) {
        setError("");
        const fileList = e.target.files;
        if (!fileList) return;
        if (fileList[0].size >= 5000000) {
            setError("Размер файла не должен превышать 5Мб.");
            return;
        }
        getBase64(fileList[0], ((b64) => {
            //setBranding({...branding, b64Background: b64});
            let request: IChallengeBranding = {b64Background: b64}
            setTimeout(() => {handleSubmit(request)}, 200);
        }));
    };

    const handleSubmit = (request: IChallengeBranding) => {
        ChallengeAPI.updateChallengeBranding(toNumber(params.id), request, (response) => {
            if (response.status === 200) {
                window.location.reload();
            } else {
                setError("Ошибка при сохранении настроек");
            }
        })
    }

    const handleDeleteBackground = () => {
        ChallengeAPI.deleteBrandingBackground(toNumber(params.id), (response) => {
            if (response.status === 200) {
                window.location.reload();
            } else {
                setError("Ошибка удаления изображения");
            }
        })
    }

    const handleLandingPublication = () => {
        LandingAPI.publish(toNumber(params.id), (response) => {
            if (response.status === 200) {
                setLanding({...landing, landingPublished: !landing?.landingPublished});
            } else {
                setError("Ошибка публикации лендинга");
            }
        })
    }

    const initLanding = () => {
        LandingAPI.initLanding(toNumber(params.id), (response) => {
            if (response.status === 200) {
                window.location.href = `/landing/${params.id}`
            } else {
                setError("Ошибка создания лендинга");
            }
        })
    }

    return (
        <div className="container-right-block">
            <div className="menu-challenge">
                <ModalMessage msg={error} header={"Ошибка"} _callback={() => setError("")}/>
                <h2 className="headline-right-block">Оформление</h2>
                <a href="https://disk.yandex.ru/d/EZGmSQZwhl4YzQ" target={"_blank"} className="card-standart-link">
                    Инструкции по оформлению
                </a>
                <fieldset className="test-block-quiestion">
                    <p className="btn-info-text">
                        Загрузите главное изображение или видео, которое будет использоваться на лендинге вашего мероприятия, а также в личных кабинетах участников
                    </p>
                    <p className="btn-info-text">
                        Загружаемый контент должен иметь пропорции 1:1. Максимальное разрешение в px от 1080x1080 до
                        2160x2160. Для изображений используются форматы jpg или png, весом не более 2 мегабайта. Для видео используется формат mp4 с кодеком h.264, без аудиодорожки, максимальный битрейт 5
                        мегабит, вес файла не более 5 мегабайт
                    </p>
                    <input type="file" accept=".jpg,.jpeg,.mp4" id="load-image-design" onChange={handleFileChange} className="load-image-design"/>
                        <label htmlFor="load-image-design" className="load-design-test-block">
                            <p className="load-image-test-btn" style={{cursor: "pointer"}}>Изображение или видео</p>
                        </label>
                    {branding?.backgroundPath ?
                        <div className="flex-btn-stage">
                            <div className="img-load-block">
                                {branding.backgroundPath.split('.').pop() === "jpg" ?
                                    <img src={CONFIG.STORAGE_URL + "/" + branding?.backgroundPath} alt=""
                                         className="img-load"/>
                                    :
                                    <video autoPlay muted loop playsInline className="img-load">
                                        <source src={CONFIG.STORAGE_URL + "/" + branding.backgroundPath}/>
                                    </video>
                                }
                                <p className="img-load-text">{branding.backgroundPath.split('.').pop() === "jpg" ? "Загружено изображение" : "Загружено видео"}</p>
                            </div>
                            <img src="/images/delete_red.svg" alt="" className="img-load-del" onClick={handleDeleteBackground} style={{cursor: "pointer"}}/>
                        </div>
                        :
                        <></>
                    }
                </fieldset>
                <div className="btn-info">
                    <h4 className="btn-info-settings">
                        Настройки лендинга
                    </h4>
                    {
                        landing && landing.landingExists ?
                            <>
                                <p className="btn-info-text">
                                    Имя лендинга: <a href={`${window.location.origin}/landing/${challenge.alias ? challenge.alias : params.id}`} target={"_blank"} className="active-link">{`${window.location.origin}/landing/${challenge.alias ? challenge.alias : params.id}`}</a>
                                </p>
                                {/*<input type="text" name="" id="" className="input-challenges" placeholder="name"/>*/}
                                {/*<p className="btn-info-text">
                                    В строке «цвет» укажите номер (HEX) вашего фирменного цвета в формате #000000.
                                </p>
                                <input type="text" name="" id="" className="input-challenges" placeholder="#00000"/>*/}
                                <p className="btn-info-text">
                                    Для публикации лендинга нажмите на кнопку «Опубликовать». Если в дальнейшем,
                                    Вы захотели что-то изменить, нажмите на кнопку редактировать. Вы также можете
                                    снять лендинг с публикации. Он не будет удален и всегда будет доступен в
                                    настройках
                                </p>
                                <div className="flex-btn-stage">
                                    <a href={`https://challenge.braim.org/landing/${params.id}`} target={"_blank"} className="btn-blue-width">Редактировать</a>
                                    {
                                        landing.landingPublished ?
                                            <div className="btn-main" style={{cursor: "pointer", width: "fit-content"}} onClick={handleLandingPublication}>
                                                <p className="img-load-design">Снять с публикации</p>
                                            </div>
                                            :
                                            <div className="btn-main" style={{cursor: "pointer", width: "fit-content"}} onClick={handleLandingPublication}>
                                                <p className="img-load-design">Опубликовать</p>
                                            </div>
                                    }
                                </div>
                            </>
                            :
                            <>
                                <p className="btn-info-text">
                                    Всего в пару кликов вы можете создать страницу вашего мероприятия. Для этого нажмите на кнопку "Создать лендинг". Данные из карточки вашего мероприятия автоматически будут перенесены на страницу
                                </p>
                                <a className="btn-blue-width" onClick={initLanding}>Создать лендинг</a>
                            </>

                    }
                </div>
            </div>
        </div>
    )
}